<template>
    <CContainer fluid class="timmer">
        <!-- <p>TRIMMER</p> -->
        <!-- <p>{{ params }}</p> -->
        <CRow>
            <splitpanes class="default-theme" @ready="onPanesReady" @resize="onPanesResize" @resized="onPanesResize">

                <pane size="80">
                    <CRow class="m-1">
                        <CCol sm="auto">
                            <span>{{ trimmer_header.project }} / {{ trimmer_header.shooting_day }} / {{ trimmer_header.order}}</span>
                        </CCol>
                        <CCol sm="auto" class="ms-auto text-end">
                            <CButton size="sm" color="success" @click="onUnmuteAll">Unmute All</CButton>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CRow class="videozone" ref="videozone">
                                <div id="videos" ref="videos" class="videos position-relative" @resize="onVideosResize">
                                    <CentroidVideoPlayer v-for="(vp, index) in media" 
                                        ref="cam"  
                                        @duration="onDuration" 
                                        @currentTime="onCurrentTime" 
                                        @loopEnd="onLoopEnd" 
                                        @solo="onSolo"
                                        @rotated="recalcLayout"
                                        />
                                </div>
                            </CRow>
                            <!-- <CRow class="videozone">
                                <CRow class="mt-2">
                                    <CCol sm="4" class="px-1">
                                        <CentroidVideoPlayer class="videoplayer" ref="cam0" @duration="onDuration" @currentTime="onCurrentTime" @loopEnd="onLoopEnd" @solo="onSolo"/>
                                    </CCol>
                                    <CCol sm="4" class="px-1">
                                        <CentroidVideoPlayer class="videoplayer" ref="cam1" @duration="onDuration"  @solo="onSolo"/>
                                    </CCol>
                                    <CCol sm="4" class="px-1">
                                        <CentroidVideoPlayer class="videoplayer" ref="cam2" @duration="onDuration"  @solo="onSolo"/>
                                    </CCol>
                                </CRow>
                                <CRow class="mt-2">
                                    <CCol sm="4" class="px-1">
                                        <CentroidVideoPlayer class="videoplayer" ref="cam3" @duration="onDuration"  @solo="onSolo"/>
                                    </CCol>
                                    <CCol sm="4" class="px-1">
                                        <CentroidVideoPlayer class="videoplayer" ref="cam4" @duration="onDuration"  @solo="onSolo"/>
                                    </CCol>
                                    <CCol sm="4" class="px-1">
                                        <CentroidVideoPlayer class="videoplayer" ref="cam5" @duration="onDuration"  @solo="onSolo"/>
                                    </CCol>
                                </CRow>
                            </CRow> -->
                            <CRow>
                                <CCol sm="12">
                                    <Timeline ref="tmln" class="tmln" @scrub="onSeek" :duration="duration" />
                                </CCol>
                            </CRow>
                            <CRow class="mt-1">
                                <CCol sm="auto">
                                    <CButton size="sm" color="success" @click="playAll()">PLAY / PAUSE</CButton>
                                </CCol>
                                <CCol sm="auto">
                                    <CButtonGroup size="sm" color="success" role="playsel" aria-label="Play selection">
                                        <CButton color="success" @click="playSel(false)">Play Sel</CButton>
                                        <CButton color="success" @click="playSel(true)">Loop</CButton>
                                    </CButtonGroup>
                                </CCol>
                                <CCol sm="auto">
                                    <CButtonGroup size="sm" color="success" role="playcut" aria-label="Play cut">
                                        <CButton color="success" @click="playCut(false)">Play Cut</CButton>
                                        <CButton color="success" @click="playCut(true)">Loop</CButton>
                                    </CButtonGroup>
                                </CCol>

                                <CCol sm="auto" v-if="this.$store.state.isTechnician == false">
                                    <CButton size="sm" color="success" @click="addBodyCut">ADD BODY CUT</CButton>
                                </CCol>
                                <CCol sm="auto" v-if="this.$store.state.isTechnician == false">
                                    <CButton size="sm" color="success" @click="addFaceCut">ADD FACE CUT</CButton>
                                </CCol>
                                <CCol sm="auto" v-if="this.$store.state.isTechnician == false">
                                    <CButton size="sm" color="success" @click="updateCut">UPDATE CUT</CButton>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </pane>
                <pane size="20" min-size="20" style="font-size: 0.75em;">
                    <CRow>
                        <CCol class="d-flex justify-content-center bg-secondary"><strong>- Orders/Sets -</strong></CCol>
                    </CRow>
                    <CRow class="bg-dark">
                        <CCol sm="8">
                            <v-select ref="vsorders" id="vsorders" label="label" v-model="selOrder" :options="orders"
                                @option:selected="onSelOrderChanged">
                            </v-select>
                        </CCol>
                        <CCol sm="4">
                            <CDropdown variant="btn-group" dark>
                                <CDropdownToggle color="primary" size="sm">Filter {{ detailsData.priorityFilter }}
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    <CDropdownItem @click="onFilterPriority(0)">All</CDropdownItem>
                                    <CDropdownItem @click="onFilterPriority(1)">1</CDropdownItem>
                                    <CDropdownItem @click="onFilterPriority(2)">2</CDropdownItem>
                                    <CDropdownItem @click="onFilterPriority(3)">3</CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>

                        </CCol>
                    </CRow>

                    <CRow class="mt-1">
                        <CCol class="d-flex justify-content-center bg-secondary"><strong>- Takes -</strong></CCol>
                    </CRow>

                    <CRow>
                        <CCol class="mouse">
                            <!-- <p>Cut list here</p> -->
                            <vuetable ref="tbltakes" id="tbltakes" :api-mode="false" :data="tbl.data" track-by="id"
                                :fields="tbl.fields" :css="css.table" table-height="55vh"
                                :detail-row-component='tbl.detailRow' :showHeader="false" :rowClass="onRowClass"
                                :detailRowOptions="detailsData" @vuetable:cell-clicked="onCellClicked"
                                @loadTake="onLoadTake">
                            </vuetable>
                        </CCol>
                    </CRow>

                    <CRow class="mt-1">
                        <CCol class="d-flex justify-content-center bg-secondary"><strong>- Actors in cut -</strong>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol class="bg-secondary">
                            <vuetable ref="tblactors" id="tblactors" table-height="15vh" :showHeader="false"
                                :api-mode="false" :data="tblactors.actors" track-by="actorId" :fields="tblactors.fields"
                                :css="css.table" @vuetable:checkbox-toggled="onActorToggle">
                            </vuetable>
                        </CCol>
                    </CRow>
                    <CRow class="mt-1">
                        <CCol>
                            <CRow>
                                <CCol class="d-flex justify-content-center bg-secondary"><strong>- Comment -</strong>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <CFormTextarea id="exampleFormControlTextarea1" rows="2" maxlength="250"
                                        v-model="editComment" @click="onFocusComment" @blur="restoreKeyboardFocus"
                                        style="background-color:#4f5d73; color:white;">
                                    </CFormTextarea>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                    <CRow class="text-dark align-bottom">
                        <CCol class="p-1">
                            <CCard>
                                <CCardHeader align="center" class="p-1"><strong>TAKE</strong></CCardHeader>
                                <CCardBody class="p-1">
                                    <CRow>
                                        <CCol sm="4" align="center"><strong>TAKE</strong></CCol>
                                        <CCol sm="4" align="center"><strong>PAID</strong></CCol>
                                        <CCol sm="4" align="center"><strong>TOTAL</strong></CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="4" align="center">{{ format2Dec(takePrice.take) }}</CCol>
                                        <CCol sm="4" align="center">{{ format2Dec(takePrice.paid) }}</CCol>
                                        <CCol sm="4" align="center">{{ format2Dec(takePrice.total) }}</CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                        <CCol class="p-1">
                            <CCard>
                                <CCardHeader align="center" class="p-1"><strong>ORDER</strong></CCardHeader>
                                <CCardBody class="p-1">
                                    <CRow>
                                        <CCol sm="12" align="center"><strong>TOTAL</strong></CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12" align="center">{{ format2Dec(orderPrice) }}</CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </pane>
            </splitpanes>
        </CRow>
    </CContainer>
</template>

<script>
import { defineAsyncComponent, markRaw, nextTick } from 'vue';
import axios from 'axios';
import { CContainer } from '@coreui/vue-pro';

import { Splitpanes, Pane } from '@/components/splitpanes/src/components/splitpanes'
//import 'splitpanes/dist/splitpanes.css'

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import CentroidVideoPlayer from "@/components/centroid/CentroidVideoPlayer.vue";
import Timeline from "@/views/common/Timeline.vue";

import Vuetable from '@/components/vue3-vuetable/src/components/Vuetable.vue';
const VuetableFieldCheckbox = () => import('@/components/vue3-vuetable/src/components/VuetableFieldCheckbox.vue');
//Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox)

//import Vuetable from 'vue3-vuetable';
import TakesTblActions from './TakesTblActions.vue';
import TakesTblDetails from './TakesTblDetails.vue';
//Vue.component('takes-tbl-actions', TakesTblActions);

import TrimmerAPI from "@/utils/api.js";

export default {
    name: 'Trimmer',
    components: {
        CContainer,
        Splitpanes,
        Pane,
        "v-select": vSelect,
        CentroidVideoPlayer,
        Timeline,
        'vuetable': Vuetable,
        TakesTblActions,
        'takes-tbl-details': TakesTblDetails,
    },
    provide: function () {
        return {
            doLoadTake: this.onLoadTake,
            doSelectCut: this.onSelectCut,
            showContextMenu: this.onShowContextMenu,
        };
    },

    data() {
        return {
            sday: 0,
            orderId: 0,
            css: {
                table: {
                    tableClass: "table table-dark table-striped table-hover table-sm selectable align-middle",
                    ascendingIcon: "glyphicon glyphicon-chevron-up",
                    descendingIcon: "glyphicon glyphicon-chevron-down",
                    handleIcon: "glyphicon glyphicon-menu-hamburger",
                    renderIcon: function (classes, options) {
                        return `<span class="${classes.join(' ')}"></span>`
                    }
                }
            },
            tblactors: {
                actors: {
                    data: []
                },
                fields: [
                    {
                        name: markRaw(defineAsyncComponent(VuetableFieldCheckbox)),
                        titleClass: 'center aligned',
                        dataClass: 'center aligned',
                        disabled: this.$store.state.isTechnician || this.$store.state.isTechnician
                    },
                    {
                        title: "Actor",
                        name: 'actor.name',
                        width: "40%",
                    },
                    {
                        title: "Role",
                        name: 'actor_role.name',
                        width: "40%",
                    },
                ],
            },
            tbl: {
                detailRow: markRaw(defineAsyncComponent(() => import('./TakesTblDetails.vue'))),
                fields: [{
                    title: "Take",
                    name: 'name',
                    width: "80%",
                }, {
                    title: "Actions",
                    name: markRaw(defineAsyncComponent(() => import('./TakesTblActions.vue')))
                },
                ],
                data: {
                    data: []
                }
            },
            params: {},
            takes: [],
            orders: [],
            selOrder: '',
            selectedTake: null,
            selectedCut: null,
            playing: false,
            duration: 100,
            videoReady: 0,
            playPos: 0,
            media: [],
            playbackSpeed: 1,
            detailsData: {
                timecode: null,
                orderId: 0,
                priorityFilter: 1,
            },
            comment: "",
            editComment: "",
            takePrice: {
                take: 0,
                paid: 0,
                total: 0
            },
            orderPrice: 0,
            trimmer_header:
            {
                project: '',
                shooting_day: '',
                order: '',
                orderid: 0
            }
        }
    },
    async mounted() {
        //console.log("TRIMMER MOUNTED");
        window.addEventListener('resize', this.onVideosResize);

        this.params = this.$route.query;
        //console.log(this.$route.query);
        //console.log(this.params);
        this.sday = this.$route.query.sday;
        this.orderId = this.$route.query.order;
        //console.log(axios.defaults);
        this.fetchTakes(this.params.sday);
        //this.fetchCuts(this.params.order);
        this.detailsData.orderId = this.params.order;
        this.detailsData.priorityFilter = 0;
        this.fetchOrderInfo(this.detailsData.orderId);
        this.fetchOrders(this.params.sday);
        this.installKeyboardEvents();

        await nextTick();
        this.$refs.tmln.resizeCanvas();
    },
    unmounted() {
        window.removeEventListener('resize', this.onVideosResize);
        this.removeKeyboardEvents();
    },
    methods: {
        format2Dec(val) {
            return (Math.round(val * 100) / 100).toFixed(2);
        },
        installKeyboardEvents() {
            window.addEventListener("keydown", this.onkeydown);
        },
        removeKeyboardEvents() {
            window.removeEventListener("keydown", this.onkeydown);
        },
        onkeydown(event) {
            //console.log(event);
            if (event.code == "KeyI") {
                var cf = this.$refs.tmln.getCurrentFrame();
                this.$refs.tmln.setSelectionStart(cf);
            }
            else if (event.code == "KeyO") {
                var cf = this.$refs.tmln.getCurrentFrame();
                this.$refs.tmln.setSelectionEnd(cf);
            }
            else if (event.code == "ArrowLeft" || event.code == "KeyS") {
                this.pause(0.0);
                this.$refs.tmln.prevFrame();
            }
            else if (event.code == "ArrowRight" || event.code == "KeyF") {
                this.pause(0.0);
                this.$refs.tmln.nextFrame();
            }
            else if (event.code == "KeyJ") {
                this.modifySpeed(-0.5);
            }
            else if (event.code == "KeyK") {
                this.pause(0.0);
            }
            else if (event.code == "KeyL") {
                this.modifySpeed(0.5);
            }
            else if (event.code == "Minus") {
                this.$refs.tmln.zoomOut();
            }
            else if (event.code == "Equal") {
                this.$refs.tmln.zoomIn();
            }
            else if (event.code == "Enter" && event.ctrlKey) {
                this.addBodyCut();
            }
            else if (event.code == "Enter" && event.shiftKey) {
                this.addFceCut();
            }
            else if (event.code == "Enter" && event.altKey) {
                this.updateCut();
            }
            else if (event.code == "Space") {
                event.preventDefault();
                this.playAll();
            }
        },
        onFocusComment() {
            //console.log("onFocusComment");
            this.removeKeyboardEvents();
        },
        restoreKeyboardFocus() {
            //console.log("restoreKeyboardFocus");
            this.installKeyboardEvents();

            if (this.selectedCut == null)
                return;

            if (this.editComment != this.comment) {
                //console.log("UPDATE COMMENT");

                this.selectedCut.comment = this.editComment;
                axios({
                    method: "post",
                    url: this.purl + "/api/trimmer/cuts/comment/" + this.selectedCut.id,
                    data: {
                        comment: this.editComment,
                    },
                    headers: { "Content-Type": "application/json" },
                }).then((response) => {
                    //handle success
                    //console.log("success...");
                    //console.log(response.data);

                }).catch((response) => {
                    //handle error
                    console.log(response);
                });
            }

        },
        async fetchTakes(sdayid) {
            var ret = await TrimmerAPI.fetchTakes(this.purl, sdayid);
            //console.log("fetchTakes: ", ret);
            if (ret != null) {
                this.takes = ret.records;
                this.tbl.data = ret.records;
                this.$refs.tmln.resizeCanvas();
            }
        },
        async onFilterPriority(p) {
            //console.log("onFilterPriority " + p);
            await nextTick();
            this.detailsData.priorityFilter = p;
            //this.$refs.tbltakes.refresh();
        },
        async fetchOrderInfo(orderID) {
            if (orderID == 0) {
                this.trimmer_header.order = "All Cuts";
                this.trimmer_header.orderid = 0;
            }
            else {
                var ret = await TrimmerAPI.fetchOrderInfo(this.purl, orderID);
                if (ret != null) {
                    //console.log(ret);
                    this.trimmer_header.project = ret.project;
                    this.trimmer_header.shooting_day = ret.shooting_day;
                    this.trimmer_header.order = ret.order;
                    this.trimmer_header.orderid = ret.orderid;
                }
            }
        },
        async fetchOrders(sdayid) {
            var ret = await TrimmerAPI.fetchOrders(this.purl, sdayid);
            if (ret != null) {
                //console.log("fetchOrders: ", ret);
                var all = [];
                all.push({
                    date: "",
                    id: 0,
                    label: "All Cuts",
                    shootingDayId: sdayid,
                    status: 0
                });
                //console.log("fetchOrders");
                //console.log(this.detailsData.orderId);
                var selindex = 0;
                for (var i = 0; i < ret.records.length; i++) {
                    var item = {
                        date: ret.records[i].date,
                        id: ret.records[i].id,
                        label: ret.records[i].name,
                        shootingDayId: ret.records[i].shootingDayId,
                        status: ret.records[i].status
                    };
                    //console.log(item);
                    if (ret.records[i].id == this.detailsData.orderId)
                        selindex = i+1; // zbog all orders
                    all.push(item);
                }
                this.orders = all;
                // if (this.detailsData.orderId > this.orders.length)
                //     this.detailsData.orderId = 0;
                //this.selOrder = this.orders[this.detailsData.orderId];
                this.selOrder = this.orders[selindex]; 
                //console.log("this.selOrder = ", this.selOrder);
            }
        },

        async fetchActorsInTake() {
            var ret = await TrimmerAPI.fetchActorsInTake(this.purl, this.selectedTake.id);
            if (ret != null) {
                this.tblactors.actors = [];
                await nextTick();
                this.tblactors.actors = ret.records;
            }
        },

        async fetchActorsInCut(cutid) {
            var ret = await TrimmerAPI.fetchActorsInCut(this.purl, cutid);
            if (ret != null) {
                this.$refs.tblactors.clearSelectedValues();
                var actors = ret.actors;
                for (var i = 0; i < actors.length; i++) {
                    this.$refs.tblactors.selectId(actors[i].id);
                }
            }
        },
        onTakeSelected(e) {
            //console.log(e);
            this.selectedTake = e;
        },
        onSelectCut(cut) {
            //console.log("select cut");
            //console.log(cut);
            // console.log(this.selectedTake);
            if (this.selectedTake != null) {
                if (cut.takeId == this.selectedTake.id) {
                    this.selectedCut = cut;
                    this.$refs.tmln.setCut(this.selectedCut.startFrame, this.selectedCut.endFrame);
                    this.$refs.tmln.setSelection(this.selectedCut.startFrame, this.selectedCut.endFrame);
                    this.fetchActorsInCut(this.selectedCut.id);
                    this.comment = cut.comment;
                    this.editComment = this.comment;
                    return true;
                }
            }
            return false;
        },
        onVideosResize() {
            this.recalcLayout();    
        },
        recalcLayout() {
            if (this.$refs.cam === undefined)
                return;

            //console.log("Recalc Layout");
            //console.log(this.$refs.videos);
            //console.log(`VIDEOZONE DIV SIZE: ${this.$refs.videos.parentElement.clientWidth} , ${this.$refs.videos.parentElement.clientHeight}`);
            var padding = 10;

            var width = this.$refs.videos.parentElement.clientWidth - padding/2;
            var height = this.$refs.videos.parentElement.clientHeight - padding/2;
            var num_vid = this.$refs.cam.length;

            var numv = 0; var vert_vids = [];
            var numh = 0; var hor_vids = [];

            for (var i = 0; i < num_vid; i++){
                if (this.$refs.cam[i].isVertical())
                {
                    numv++;
                    vert_vids.push(i);
                }
                else
                {
                    numh++;
                    hor_vids.push(i);
                }
            }

            var row_size = 4;
            var hor_offset = 0;
            
            if (numv > 0)
            {
                var widht_for_vertical = height / 16 * 9;
                hor_offset = numv * widht_for_vertical;
                for (var i=0; i<vert_vids.length; i++)
                {
                    var index = vert_vids[i];
                    var xpos = i * widht_for_vertical;
                    var ypos = 0;
                    this.$refs.cam[index].setPositionAndSize(padding/2 + xpos, padding/2 + ypos, widht_for_vertical - padding, height - padding);
                    //console.log(padding/2 + xpos, padding/2 + ypos, widht_for_vertical - padding, height - padding);
                    row_size--;                    
                }
                if (row_size <= 1) row_size = 1;
            }

            if (numh <= 4) row_size = 2;

            var num_rows = Math.ceil(numh / row_size);
            var vids_per_row = Math.ceil(numh / 2);
            //console.log(num_rows, vids_per_row);

            var video_width = (width - hor_offset) / vids_per_row;
            var video_height = height / num_rows;

            for (var i=0; i<hor_vids.length; i++)
            {
                var index = hor_vids[i];
                var ypos = Math.floor(i / vids_per_row);
                var xpos = i % vids_per_row;
                //console.log(xpos, ypos);
                this.$refs.cam[index].setPositionAndSize(padding/2 + hor_offset + video_width*xpos, padding/2 + video_height*ypos, video_width - padding, video_height - padding);
                //console.log(padding/2 + hor_offset + video_width*xpos, padding/2 + video_height*ypos, video_width - padding, video_height - padding);
            }
        },

        async onLoadTake(take) {
            //console.log("***** onLoadTake");
            this.$refs.tmln.reset(this.duration, take.timecode);

            this.selectedTake = take;
            var tid = take.id;
            this.$refs.tbltakes.selectId(take.id);
            //console.log(take);
            var ret = await TrimmerAPI.fetchTakeMedia(this.purl, tid);
            if (ret != null) {
                this.fetchActorsInTake();
                this.selectedCut = null;
                this.$refs.tmln.setCut(-1, -1);

                this.media = ret.media;

                // remove thos line!!!
                // while (this.media.length > 4)
                //     this.media.shift();

                //console.log(this.media);

                await nextTick();
                this.duration = 0;
                this.videoReady = 0;
                this.playbackSpeed = 1;
                var tmlntc = this.$refs.tmln.getTimecodeInstance();

                for (var i = 0; i < this.$refs.cam.length; i++)
                    this.$refs.cam[i].loadVideo(this.params.sday, tid, i + 1, this.media[i], tmlntc);

                this.recalcLayout();
                await this.updateTakePrice(this.selectedTake.id);
                await this.updateOrderPrice(this.orderId, this.sday);
            }
        },

        onUnmuteAll() { 
            for (var i = 0; i < this.$refs.cam.length; i++)
                this.$refs.cam[i].setVolume(1);
        },
        async onSolo(id) {
            for (var i = 0; i < this.$refs.cam.length; i++)
                this.$refs.cam[i].setVolume(id == (i+1) ? 1 : 0);
            // this.$refs.cam0.setVolume(id == 1 ? 1 : 0);
            // this.$refs.cam1.setVolume(id == 2 ? 1 : 0);
            // this.$refs.cam2.setVolume(id == 3 ? 1 : 0);
            // this.$refs.cam3.setVolume(id == 4 ? 1 : 0);
            // this.$refs.cam4.setVolume(id == 5 ? 1 : 0);
            // this.$refs.cam5.setVolume(id == 6 ? 1 : 0);
        },

        async onDuration(v) {
            if (v.duration > this.duration)
                this.duration = v.duration;
            this.videoReady++;
            if (this.videoReady == this.media.length) {
                //console.log("call timeline reset");
                this.playbackSpeed = 1;
                this.$refs.tmln.resizeCanvas();
                this.$refs.tmln.reset(this.duration, this.selectedTake.timecode);
                this.detailsData.timecode = this.$refs.tmln.getTimecodeInstance();
            }
        },

        async onCurrentTime(cid, v) {
            if (cid == 1) {
                //console.log("onCurrentTime", v);
                this.playPos = v;
                this.$refs.tmln.setCurrentTime(v);
            }
        },

        async onLoopEnd(state) {
            //console.log(state);
            if (state.loop == false) {
                for (var i = 0; i < this.$refs.cam.length; i++)
                    this.$refs.cam[i].pause();
                
                // this.$refs.cam0.pause();
                // this.$refs.cam1.pause();
                // this.$refs.cam2.pause();

                // this.$refs.cam3.pause();
                // this.$refs.cam4.pause();
                // this.$refs.cam5.pause();
            }
            else {
                //console.log("SEEK ", state.start);
                for (var i = 0; i < this.$refs.cam.length; i++)
                    this.$refs.cam[i].seek(state.start);

                // this.$refs.cam0.seek(state.start);
                // this.$refs.cam1.seek(state.start);
                // this.$refs.cam2.seek(state.start);

                // this.$refs.cam3.seek(state.start);
                // this.$refs.cam4.seek(state.start);
                // this.$refs.cam5.seek(state.start);
            }
        },

        async onSeek(e) {
            for (var i = 0; i < this.$refs.cam.length; i++)
                    this.$refs.cam[i].seek(e);

            // this.$refs.cam0.seek(e);
            // this.$refs.cam1.seek(e);
            // this.$refs.cam2.seek(e);

            // this.$refs.cam3.seek(e);
            // this.$refs.cam4.seek(e);
            // this.$refs.cam5.seek(e);

            //console.log(this.playPos);
        },
        pause(s) {
            for (var i = 0; i < this.$refs.cam.length; i++)
                this.$refs.cam[i].pause();


            // this.$refs.cam0.pause();
            // this.$refs.cam1.pause();
            // this.$refs.cam2.pause();

            // this.$refs.cam3.pause();
            // this.$refs.cam4.pause();
            // this.$refs.cam5.pause();
            this.playbackSpeed = this.$refs.cam[0].getPlaybackSpeed();
            this.$refs.tmln.setPlaybackSpeed(this.playbackSpeed);
            this.playing = false;
        },
        modifySpeed(s) {
            for (var i = 0; i < this.$refs.cam.length; i++)
                this.$refs.cam[i].modifySpeed(s);


            // this.$refs.cam0.modifySpeed(s);
            // this.$refs.cam1.modifySpeed(s);
            // this.$refs.cam2.modifySpeed(s);
            // this.$refs.cam3.modifySpeed(s);
            // this.$refs.cam4.modifySpeed(s);
            // this.$refs.cam5.modifySpeed(s);
            this.playbackSpeed = this.$refs.cam[0].getPlaybackSpeed();
            this.$refs.tmln.setPlaybackSpeed(this.playbackSpeed);
        },
        async playRange(loop, r) {
            var tc = this.$refs.tmln.getTimecodeInstance();
            //console.log(tc);
            if (tc != null) {
                var stFrame = { currentTime: 0, frame: r.start + tc.getFrames() };
                var edFrame = { currentTime: 0, frame: r.end + tc.getFrames() };

                for (var i = 0; i < this.$refs.cam.length; i++) {
                    this.$refs.cam[i].pause();
                    this.$refs.cam[i].seek(stFrame);
                }

                for (var i = 0; i < this.$refs.cam.length; i++) {
                    this.$refs.cam[i].playRange(stFrame, edFrame, loop);
                }


                // this.$refs.cam0.pause(); this.$refs.cam0.seek(stFrame);
                // this.$refs.cam1.pause(); this.$refs.cam1.seek(stFrame);
                // this.$refs.cam2.pause(); this.$refs.cam2.seek(stFrame);

                // this.$refs.cam3.pause(); this.$refs.cam3.seek(stFrame);
                // this.$refs.cam4.pause(); this.$refs.cam4.seek(stFrame);
                // this.$refs.cam5.pause(); this.$refs.cam5.seek(stFrame);


                // this.$refs.cam0.playRange(stFrame, edFrame, loop);
                // this.$refs.cam1.playRange(stFrame, edFrame, loop);
                // this.$refs.cam2.playRange(stFrame, edFrame, loop);

                // this.$refs.cam3.playRange(stFrame, edFrame, loop);
                // this.$refs.cam4.playRange(stFrame, edFrame, loop);
                // this.$refs.cam5.playRange(stFrame, edFrame, loop);

                //this.playbackSpeed = this.$refs.cam0.getPlaybackSpeed();
                //this.$refs.tmln.setPlaybackSpeed(this.playbackSpeed);
            }
        },
        async playSel(loop) {
            var sel = this.$refs.tmln.getSelectionRange();  // in frames           
            //console.log(sel);
            this.playRange(loop, sel);
        },
        async playCut(loop) {
            var sel = this.$refs.tmln.getCut();  // in frames           
            //console.log(sel);
            this.playRange(loop, sel);
        },
        async playAll() {
            for (var i = 0; i < this.$refs.cam.length; i++) 
                this.$refs.cam[i].togglePlay(false);


            // this.$refs.cam0.togglePlay(false);
            // this.$refs.cam1.togglePlay(false);
            // this.$refs.cam2.togglePlay(false);

            // this.$refs.cam3.togglePlay(false);
            // this.$refs.cam4.togglePlay(false);
            // this.$refs.cam5.togglePlay(false);
            this.playbackSpeed = this.$refs.cam[0].getPlaybackSpeed();
            this.$refs.tmln.setPlaybackSpeed(this.playbackSpeed);
        },

        async updateTakePrice(takeId) {
            var ret = await TrimmerAPI.updateTakePrice(this.purl, takeId);
            if (ret != null) {
                this.takePrice = ret.take_price;
            }
        },

        async updateOrderPrice(orderId, sdayid) {
            var ret = await TrimmerAPI.calcOrderPrice(this.purl, orderId, sdayid);
            if (ret != null) {
                this.orderPrice = ret.total;
            }
        },

        async updateCut() {
            if (this.selectedTake == null)
                return;

            var cutRange = this.$refs.tmln.getSelectionRange();
            var ret = await TrimmerAPI.updateCut(this.purl, this.selectedCut.id, cutRange);
            if (ret != null) {
                this.refreshDetailsTable(this.selectedTake.id);
                await this.updateTakePrice(this.selectedTake.id);
                await this.updateOrderPrice(this.orderId, this.sday);
                this.$refs.tmln.setCut(cutRange.start, cutRange.end);
            }
        },
        async addCut(type, tid, oid) {
            var cutRange = this.$refs.tmln.getSelectionRange();
            var ret = await TrimmerAPI.addCut(this.purl, type, tid, oid, cutRange);
            if (ret != null) {
                this.selectedTake.cuts.push(ret);
                this.refreshDetailsTable(tid);
                await this.updateTakePrice(tid);
                await this.updateOrderPrice(this.orderId, this.sday);
                this.$refs.tmln.setCut(cutRange.start, cutRange.end);
                ret["takeId"] = this.selectedTake.id;
                //console.log(ret);
                this.onSelectCut(ret);
            }
        },
        addFaceCut() {
            if (this.selectedTake == null)
                return;

            this.addCut(2, this.selectedTake.id, this.params.order);
        },
        addBodyCut() {
            if (this.selectedTake == null)
                return;

            this.addCut(1, this.selectedTake.id, this.params.order);
        },
        onCellClicked(data, field, event) {
            //console.log('cellClicked:');
            //console.log(data);
            //this.$refs.tbltakes.toggleDetailRow(data.data.id);
            //this.$refs.vtbl.closeAllDetailRows();
            if (this.$refs.tbltakes.isVisibleDetailRow(data.data.id) == false) {
                //console.log('detail row not visible.. show it');
                //this.$socket.emit("get_segments", { guid: data.guid });
                this.$refs.tbltakes.showDetailRow(data.data.id);
            }
            else {
                //console.log('detail visible.. hide it');
                this.$refs.tbltakes.hideDetailRow(data.data.id);
            }
        },
        async onPanesReady(e) {
            await nextTick();
            //console.log("onPanesReady");            
            this.$refs.tmln.resizeCanvas();
        },
        async onPanesResize(e) {
            await nextTick();
            //console.log("onPanesResize", e);
            this.$refs.tmln.resizeCanvas();
            this.recalcLayout();
        },
        async onSelOrderChanged(e) {

            //console.log("onSelOrderChanged: ", e);
            this.detailsData.orderId = e.id;
            this.orderId = e.id;
            await this.updateOrderPrice(this.orderId, this.sday);
            this.fetchOrderInfo(this.detailsData.orderId);
        },
        onGetOptionKey(item) {
            //console.log("onGetOptionKey ", item);
            return "id";
        },
        onGetOptionLabel(item) {
            //console.log("onGetOptionLabel", item);
            //return item.label;
            return "label";
        },
        onRowClass(item, index) {
            //console.log("onRowClass ", item, this.selectedTake);
            if (this.selectedTake == null) return 'table-dark';

            if (item.id == this.selectedTake.id)
                return 'table-danger';
            else
                return 'table-dark';
        },
        async onActorToggle(state, item) {
            //console.log("onActorToggle");
            if (this.selectedCut == null) {
                await nextTick();
                this.$refs.tblactors.clearSelectedValues();
                return;
            }

            var method = "addactor";
            if (!state) method = "removeactor"
            var ret = await TrimmerAPI.toggleActor(this.purl, method, this.selectedCut.id, item.actor.id);
            await this.updateTakePrice(this.selectedTake.id);
            await this.updateOrderPrice(this.orderId, this.sday);
        },
        refreshDetailsTable(takeId) {
            var detailRow = this.$refs.tbltakes.getDetailRow(takeId);
            if (detailRow != null)
                detailRow.fetchCuts();
        },
        async moveCut(cutId, takeId, orderId) {
            //console.log(`IN TAKE ${takeId} MOVE CUT ${cutId} TO ORDER ${orderId}`);
            var ret = await TrimmerAPI.moveCut(this.purl, cutId, orderId);
            if (ret != null) {
                this.refreshDetailsTable(takeId);
            }
        },
        async copyCut(cutId, takeId, orderId) {
            //console.log(`IN TAKE ${takeId} COPY CUT ${cutId} TO ORDER ${orderId}`);
            await TrimmerAPI.moveCut(this.purl, cutId, orderId);
        },
        async deleteCut(cutId, takeId) {
            var ret = await TrimmerAPI.deleteCut(this.purl, cutId);
            if (ret != null) {
                this.refreshDetailsTable(takeId);
            }
        },
        onShowContextMenu(d) {
            // d is cut (id,takeId,x,y)
            //console.log(d);
            //console.log(this.orders);

            var detailRow = this.$refs.tbltakes.getDetailRow(d.takeId);
            var selCuts = detailRow.getSelectedCuts()

            //console.log("sel:", selCuts);

            if (selCuts.length == 0)
                selCuts.push(d.id);

            // destOrderId is order id... use destOrderId
            const doMove = (destOrderId) => {
                //console.log("doMove", destOrderId);
                this.moveCut(selCuts, d.takeId, destOrderId);
            }
            const doCopy = (destOrderId) => {
                //console.log(destOrderId);
                this.copyCut(selCuts, d.takeId, destOrderId);
            }
            const deleteTake = (t) => {
                //console.log(t);
                if (confirm(`Delete ${selCuts.length} cuts?`) == true) {
                    this.deleteCut(selCuts, t.takeId);
                }
            }

            var mv = [];
            var cp = [];
            for (var i = 0; i < this.orders.length; i++) {
                let order = this.orders[i];

                var mitem = {
                    label: order.label,
                    onClick: () => { doMove(order.id); }
                };
                mv.push(mitem);

                var citem = {
                    label: order.label,
                    onClick: () => { doCopy(order.id); }
                }
                cp.push(citem);
            }

            // show the context menu
            this.$contextmenu({
                theme: 'mac dark',
                x: d.x,
                y: d.y,
                items: [
                    { label: `Move ${selCuts.length} Cuts To...`, children: mv },
                    { label: `Copy ${selCuts.length} Cuts To...`, children: cp },
                    { label: `Delete ${selCuts.length} Cuts`, onClick: () => { deleteTake(d); } }
                ]
            });
        }
    }
}
</script>

<style scoped lang="scss">
.v-select::v-deep {
    --vs-controls-color: #ffffff;
    --vs-border-color: #000000;

    --vs-dropdown-bg: #000000;
    --vs-dropdown-color: #ffffff;
    --vs-dropdown-option-color: #ffffff;

    --vs-selected-bg: #664cc3;
    --vs-selected-color: #eeeeee;

    --vs-search-input-color: #eeeeee;

    --vs-dropdown-option--active-bg: #FFFFFF;
    --vs-dropdown-option--active-color: #000000;
}

.videozone {
    height: calc(100vh - 200px);
    overflow-y: clip;
}

.videos {
    overflow-y: clip;
}

.timmer {
    background: #202020;
    color: #ffffff;
    height: 100vh;
}

.tmln {
    width: 100%;
    height: 120px;
    user-select: none;
}

.cwhite {
    color: white;
}

.cutszone {
    height: 100vh;
}

.cutstable {
    height: 600px;
    overflow-y: scroll;
}

.mouse {
    cursor: default !important;
}
</style>