<template>
    <canvas ref="timeline" id="timeline" :width="size.width" :height="size.height"
        style="border: 1px solid #000000; width: 100%;"></canvas>
</template>
<script>

import { nextTick } from 'vue';
import Timecode from "@/utils/timecode.js"

const TimelineZone = {
    TZNone: 1,
    TZFrames: 2,
    TZScrollbar: 3
};

export default {
    name: 'Timeline',
    props: {
        timelineHeight: "20",
    },
    emits: ["scrub"],
    data() {
        return {
            ctx: null,
            gradient: null,
            scrollbar: {
                size: 20,
                backcolor: "#464646",
                handleColor: "#808080"
            },
            size: {
                width: 100,
                height: 10
            },
            //tlpos: 0,
            currentTime: 0,
            visRange: {
                min: 0,
                max: 20
            },
            hotArea: [
                { x: 0, y: 0, w: 0, h: 0, type: 0, val: 0, val2: 0 },
                { x: 0, y: 0, w: 0, h: 0, type: 1, val: 0, val2: 0 },
                { x: 0, y: 0, w: 0, h: 0, type: 2, val: 0, val2: 0 },
            ],
            mouseAction: -1,
            startMousePos: {
                x: 0,
                y: 0
            },
            mousePos: {
                x: 0,
                y: 0
            },
            mouseVal: 0,
            TZ: 0,
            tcWidth: 100,
            duration: 20,
            fpsNum: 25,
            fpsDen: 1,
            dividers: [],
            timecode: null,
            currentSelection: { // in frames
                start: -1,
                end: -1
            },
            currentCut: { // in frames
                start: -1,
                end: -1
            },
            playbackSpeed: 1
        }
    },
    mounted() {
        this.timecode = new Timecode;
        this.ctx = this.$refs.timeline.getContext("2d");
        this.gradient = this.ctx.createLinearGradient(0, 0, 0, 120);
        this.gradient.addColorStop(0, "#464646");
        this.gradient.addColorStop(1, "#141414");
        //console.log(`in mounted: ${this.timelineWidth} x ${this.timelineHeight}`);
        this.init();
    },
    unmounted() {
        window.removeEventListener('resize', this.resizeCanvas);
        window.removeEventListener("mousedown", this.onMouseDown);
        window.removeEventListener("mouseup", this.onMouseUp);
        window.removeEventListener("mousemove", this.onMouseMove);
    },
    methods: {
        async init() {
            await nextTick();
            window.addEventListener('resize', this.resizeCanvas);
            window.addEventListener("mousedown", this.onMouseDown);
            window.addEventListener("mouseup", this.onMouseUp);
            window.addEventListener("mousemove", this.onMouseMove);
            var num = this.fpsNum;
            var den = this.fpsDen;
            // if (den == 1001)
            // {
            //     den = 1;
            //     num = Math.floor(num / 1000);
            // }

            var dd = [
                (den * 1) / num,
                (den * 5) / num,
                num / den,
                (5 * num) / den,
                (10 * num) / den,
                (20 * num) / den,
                (30 * num) / den,
                (60 * num) / den,
                (5 * 60 * num) / den,
                (10 * 60 * num) / den,
                (20 * 60 * num) / den,
                (30 * 60 * num) / den,
                (60 * 60 * num) / den,
                (2 * 60 * 60 * num) / den,
                (3 * 60 * 60 * num) / den,
                (6 * 60 * 60 * num) / den,
                (12 * 60 * 60 * num) / den,
            ];
            this.dividers = dd;

            this.resizeCanvas();
        },
        reset(duration, startTimecode) {
            //console.log(startTimecode);
            this.duration = duration;
            this.visRange.min = 0;
            this.visRange.max = duration;
            //this.fpsNum = fpsNum;
            //this.fpsDen = fpsDen;
            this.currentTime = 0;
            this.currentSelection.start = -1;
            this.currentSelection.end = -1;
            this.timecode.initFromString(startTimecode);
            this.fpsNum = this.timecode.mFpsn;
            this.fpsDen = this.timecode.mFpsd;
            //console.log({ N:this.fpsNum, D:this.fpsDen });
            // console.log("tmln: ");
            // console.log(this.timecode.getFrames());
            // console.log(this.timecode);
            this.redraw();
        },
        setCurrentTime(t) {
            if (this.TZ == TimelineZone.TZNone) {
                this.currentTime = t;
                this.redraw();
            }
        },
        zoomIn() { 
            //console.log(this.visRange);
            //console.log(this.currentTime);
            var prevVisRange = {
                min: this.visRange.min,
                max: this.visRange.max
            };

            var dl = (this.currentTime - this.visRange.min) * 0.8; 
            var dr = (this.visRange.max - this.currentTime) * 0.8; 

            this.visRange.min = this.currentTime - dl;
            this.visRange.max = this.currentTime + dr;

            var dd = this.visRange.max - this.visRange.min;
            if (dd < 1.0) {
                this.visRange.min = prevVisRange.min;
                this.visRange.max = prevVisRange.max;
            }

            this.redraw();
        },
        zoomOut() {
            var dl = (this.currentTime - this.visRange.min) * 1.2;
            var dr = (this.visRange.max - this.currentTime) * 1.2;
            this.visRange.min = this.currentTime - dl;
            this.visRange.max = this.currentTime + dr;
            if (this.visRange.min < 0) this.visRange.min = 0;
            if (this.visRange.max > this.duration) this.visRange.max = this.duration;
            this.redraw();
        },
        setCut(startFrame, endFrame) {
            this.currentCut.start = startFrame;
            this.currentCut.end = endFrame;
            this.redraw();
        },
        setSelection(startFrame, endFrame) {
            this.currentSelection.start = startFrame;
            this.currentSelection.end = endFrame;
            this.redraw();
        },

        getCut() {
            return this.currentCut;
        },
        setSelectionStart(val) {
            this.currentSelection.start = val;
            if (this.currentSelection.end < this.currentSelection.start)
                this.currentSelection.end = this.currentSelection.start + 1;
            this.redraw();
        },
        setSelectionEnd(val) {
            this.currentSelection.end = val;
            if (this.currentSelection.start > this.currentSelection.end)
                this.currentSelection.start = this.currentSelection.end - 1;
            this.redraw();
        },
        getSelectionRange() {
            return this.currentSelection;
        },
        hitTest(mx, my) {
            for (var i = 0; i < this.hotArea.length; i++) {
                if ((mx >= this.hotArea[i].x) && (my >= this.hotArea[i].y) && (mx < (this.hotArea[i].x + this.hotArea[i].w)) && (my < (this.hotArea[i].y + this.hotArea[i].h)))
                    return this.hotArea[i].type;
            }
            return -1;
        },
        getTimecodeInstance() {
            return this.timecode;
        },
        getCurrentFrame() {
            var ret = this.timeToFrame(this.currentTime, this.fpsNum, this.fpsDen);
            //console.log(ret);
            return ret;
        },
        prevFrame() {
            this.currentTime -= this.fpsDen / this.fpsNum;
            if (this.currentTime < 0)
                this.currentTime = 0;
            var frame = this.timeToFrame(this.currentTime, this.fpsNum, this.fpsDen);
            this.$emit('scrub', { currentTime: this.currentTime, frame: frame + this.timecode.getFrames() });
            //console.log(`${this.currentTime} ${this.fpsDen / this.fpsNum}`);
            this.redraw();
        },
        nextFrame() {
            //console.log("nextFrame", { D: this.fpsDen, N: this.fpsNum });
            this.currentTime += this.fpsDen / this.fpsNum;
            if (this.currentTime > this.duration)
                this.currentTime = this.duration;
            var frame = this.timeToFrame(this.currentTime, this.fpsNum, this.fpsDen);
            //console.log(frame);
            this.$emit('scrub', { currentTime: this.currentTime, frame: frame + this.timecode.getFrames() });
            //console.log(`${this.currentTime} ${this.fpsDen / this.fpsNum}`);
            this.redraw();
        },
        setPlaybackSpeed(speed) {
            this.playbackSpeed = speed;
            this.redraw();
        },
        pixToTime(mx) {
            var w = this.ctx.canvas.clientWidth;
            var vr = this.visRange.max - this.visRange.min;
            var ret = this.visRange.min + ((vr * mx) / w);

            return ret;
        },
        timeToPix(t) {
            var w = this.ctx.canvas.clientWidth;
            var dt = t - this.visRange.min;

            var vr = this.visRange.max - this.visRange.min;
            var ret = (w * dt) / vr;

            return ret;
        },
        timeToFrame(t, num, den) {
            var f = Math.round((t * num) / den);
            return f;
        },
        frameToTime(f, num, den) {
            var t = Math.round((f * den) / num);
            return t;
        },
        frameToWindow(frame, w, visRange) {
            return ((frame - visRange.min) * w) / (visRange.max - visRange.min);
        },
        onMouseDown(e) {
            var mx = e.clientX;// + window.pageXOffset;
            var my = e.clientY;// + window.pageYOffset;
            var tmlnRect = this.$refs.timeline.getBoundingClientRect();
            var cll = { x: mx - tmlnRect.x, y: my - tmlnRect.y };
            if (cll.x >= 0 && cll.x < tmlnRect.width && cll.y >= 0 && cll.y < tmlnRect.height)
                this.xMouseDown({ x: cll.x, y: cll.y, buttons: e.buttons });
        },
        onMouseUp(e) {
            var mx = e.clientX;// + window.pageXOffset;
            var my = e.clientY;// + window.pageYOffset;
            var tmlnRect = this.$refs.timeline.getBoundingClientRect();
            var cll = { x: mx - tmlnRect.x, y: my - tmlnRect.y };
            //if (cll.x >= 0 && cll.x < tmlnRect.width && cll.y >= 0 && cll.y < tmlnRect.height)
            this.xMouseUp({ x: cll.x, y: cll.y, buttons: e.buttons });
        },
        onMouseMove(e) {
            var mx = e.clientX;// + window.pageXOffset;
            var my = e.clientY;// + window.pageYOffset;
            //console.dir(this.$refs.timeline.getBoundingClientRect());
            //return;
            var tmlnRect = this.$refs.timeline.getBoundingClientRect();
            var cll = { x: mx - tmlnRect.x, y: my - tmlnRect.y };
            //console.log(cll);
            //if (cll.x >= 0 && cll.x < tmlnRect.width && cll.y >= 0 && cll.y < tmlnRect.height)
            this.xMouseMove({ x: cll.x, y: cll.y, buttons: e.buttons });
        },

        xMouseDown(e) {
            var h = this.ctx.canvas.clientHeight;
            var mx = e.x;
            var my = e.y;
            if (e.buttons == 1) // left mouse
            {
                if (my > (h - this.scrollbar.size)) {
                    //console.log("donw on TZScrollbar");
                    this.TZ = TimelineZone.TZScrollbar;
                    this.mouseAction = this.hitTest(mx, my);
                    if (this.mouseAction != -1) {
                        this.startMousePos.x = mx;
                        this.startMousePos.y = my;
                        this.mouseVal = this.startMousePos.x - this.hotArea[this.mouseAction].val;
                    }
                }
                else {
                    //console.log("donw on TZFrames");
                    this.TZ = TimelineZone.TZFrames;
                    this.currentTime = this.pixToTime(mx);
                    var frame = this.timeToFrame(this.currentTime, this.fpsNum, this.fpsDen);
                    this.$emit('scrub', { currentTime: this.currentTime, frame: frame + this.timecode.getFrames() });
                    this.redraw();
                }
            }
        },
        xMouseUp(e) {
            //this.mouseAction.button = 0;
            if (this.TZ == TimelineZone.TZScrollbar) {
                if (this.mouseAction != -1)
                    this.mouseAction = -1;
            }
            else if (this.TZ == TimelineZone.TZFrames) {
            }

            this.TZ = TimelineZone.TZNone;

        },
        xMouseMove(e) {
            var mx = e.x;
            var my = e.y;
            var w = this.ctx.canvas.clientWidth;
            var h = this.ctx.canvas.clientHeight;
            //console.log(`mouseMove ${this.TZ}`);

            if (this.TZ == TimelineZone.TZFrames) {
                //console.log("mouseMove TZFrames");
                var pts = this.pixToTime(mx);
                pts = Math.round(pts * this.fpsNum) / this.fpsNum;
                //console.log(pts);
                if (pts < this.visRange.min) pts = this.visRange.min;
                if (pts > this.visRange.max) pts = this.visRange.max;
                this.currentTime = pts;
                var frame = this.timeToFrame(this.currentTime, this.fpsNum, this.fpsDen);
                this.$emit('scrub', { currentTime: this.currentTime, frame: frame + this.timecode.getFrames() });
                this.redraw();
            }
            else if (this.TZ == TimelineZone.TZScrollbar) {
                //console.log("mouseMove TZScrollbar");
                if (this.mouseAction != -1) {
                    this.mousePos.x = mx;
                    this.mousePos.y = my;
                    if (this.mouseAction == 0 || this.mouseAction == 1) {
                        // visRange..
                        this.hotArea[this.mouseAction].val =
                            this.mousePos.x
                            - this.mouseVal;

                        var totalRange = this.duration;
                        var prevVisStart = this.visRange.min;
                        var prevVisEnd = this.visRange.max;

                        this.visRange.min = (totalRange * this.hotArea[0].val) / w;
                        this.visRange.max = (totalRange * this.hotArea[1].val) / w;

                        var a = this.timeToFrame(this.visRange.min, this.fpsNum, this.fpsDen);
                        this.visRange.min = this.frameToTime(a, this.fpsNum, this.fpsDen);
                        var b = this.timeToFrame(this.visRange.max, this.fpsNum, this.fpsDen);
                        this.visRange.max = this.frameToTime(b, this.fpsNum, this.fpsDen);

                        if (this.mouseAction == 0) {
                            if (this.visRange.min < 0) this.visRange.min = 0;
                            if ((this.visRange.max - this.visRange.min) * w / totalRange < 55) this.visRange.min = prevVisStart;
                            this.onRangeUpdate();
                        }

                        if (this.mouseAction == 1) {
                            if (this.visRange.max > this.duration) this.visRange.max = this.duration;
                            if ((this.visRange.max - this.visRange.min) * w / totalRange < 55)
                                this.visRange.max = prevVisEnd;
                            this.onRangeUpdate();
                        }

                        this.redraw();
                    }
                    else if (this.mouseAction == 2) {
                        var prevVisStart = this.visRange.min;
                        var prevVisEnd = this.visRange.max;

                        //debugger;
                        this.hotArea[this.mouseAction].val = mx - this.mouseVal;
                        if (this.hotArea[this.mouseAction].val < 0) this.hotArea[this.mouseAction].val = 0;
                        var st = this.hotArea[this.mouseAction].val;
                        var ed = this.hotArea[this.mouseAction].val + this.hotArea[this.mouseAction].val2;
                        if (ed >= w) {
                            ed = w;
                            st = ed - this.hotArea[this.mouseAction].val2;
                        }

                        this.hotArea[0].val = st;
                        this.hotArea[1].val = ed;

                        var totalRange = this.duration;
                        this.visRange.min = ((totalRange * this.hotArea[0].val) / w);
                        this.visRange.max = ((totalRange * this.hotArea[1].val) / w);
                        this.onRangeUpdate();

                        this.redraw();
                    }
                }
            }
        },
        onRangeUpdate() {

        },

        async resizeCanvas() {
            await nextTick();
            this.$refs.timeline.width = this.$refs.timeline.clientWidth;
            this.$refs.timeline.height = this.$refs.timeline.clientHeight;
            // console.log(`resizeCanvas 1: ${this.$refs.timeline.clientWidth} x ${this.$refs.timeline.clientHeight}`);
            // console.log(`resizeCanvas 2: ${this.width} x ${this.height}`);
            // console.log(this.$refs.timeline);
            // console.log(`Resize ${this.width} x ${this.height}`);
            this.redraw();
        },
        redraw() {
            var w = this.ctx.canvas.clientWidth;
            var h = this.ctx.canvas.clientHeight;
            //console.log(`timeline redraw ${w} x ${h}`);

            this.ctx.fillStyle = this.gradient;
            this.ctx.fillRect(0, 0, w, h);

            this.drawCuts(this.ctx);
            this.drawCut(this.ctx, w, h, this.visRange, this.currentCut);
            this.drawSelection(this.ctx, w, h, this.visRange, this.currentSelection);
            this.drawCurrentFrame(this.ctx, w, h, this.visRange, this.currentTime);
            this.drawRuler(this.ctx, w, h, this.visRange);
            this.drawTCText(this.ctx, w, h, this.visRange, this.currentTime);
            this.drawScrollbar(this.ctx, w, h, this.duration, this.scrollbar, this.visRange);
        },
        drawCuts(ctx) { },
        updateHotArea(index, x0, y0, w, h) {
            this.hotArea[index].x = x0;
            this.hotArea[index].y = y0;
            this.hotArea[index].w = w;
            this.hotArea[index].h = h;
        },
        drawHotArea(ctx, index, color) {
            ctx.fillStyle = color;
            ctx.fillRect(this.hotArea[index].x, this.hotArea[index].y, this.hotArea[index].w, this.hotArea[index].h);
        },
        drawScrollbar(ctx, w, h, duration, scrollbar, visRange) {
            var y0 = h - scrollbar.size;
            var oldFillStyle = ctx.fillStyle;
            ctx.fillStyle = "#303030";
            ctx.fillRect(0, y0, w, scrollbar.size);

            // calc bar size
            var x0 = (w * visRange.min) / duration;
            var x1 = (w * visRange.max) / duration;
            //console.log(`${x0} -> ${x1} ` + (x1 - x0));

            // draw bar
            ctx.fillStyle = "#505050";
            ctx.fillRect(x0, y0, x1 - x0, scrollbar.size);

            // draw handles
            this.updateHotArea(0, x0 + 2, y0 + 2, scrollbar.size - 4, scrollbar.size - 4);
            this.updateHotArea(1, x1 - scrollbar.size + 2, y0 + 2, scrollbar.size - 4, scrollbar.size - 4);
            this.updateHotArea(2, x0 + scrollbar.size + 2, y0 + 2, x1 - x0 - 2 * scrollbar.size - 4, scrollbar.size - 4);
            this.hotArea[0].val = x0;
            this.hotArea[1].val = x1;
            this.hotArea[2].val = x0;
            this.hotArea[2].val2 = x1 - x0;

            //console.log(this.hotArea);
            this.drawHotArea(ctx, 0, "#808080");
            this.drawHotArea(ctx, 1, "#808080");
            this.drawHotArea(ctx, 2, "#606060");


            ctx.fillStyle = oldFillStyle;
        },
        findSpacing(frameWidth, minfit) {
            for (var i = 0; i < this.dividers.length; i++) {
                if ((frameWidth * this.dividers[i]) >= minfit)
                    return i;
            }

            return 0;
        },
        drawRuler(ctx, w, h, visRange) {
            var oldStrokeStyle = ctx.strokeStyle;
            var oldFillStyle = ctx.fillStyle;
            var num = this.fpsNum;
            var den = this.fpsDen;

            // if (den == 1001)
            // {
            //     den = 1;
            //     num = Math.floor(num / 1000);
            // }

            ctx.strokeStyle = "#FFFFFF";
            ctx.fillStyle = "#FFFFFF";
            ctx.font = "16px sans-serif";

            //debugger;
            var korak = 1;
            var minFrameWidth = 5;
            var frameWidth = (w * den) / ((visRange.max - visRange.min) * num);

            if ((frameWidth * 1.0) >= minFrameWidth) korak = 1;
            else if ((frameWidth * 10.0) >= minFrameWidth) korak = 10;
            else if ((frameWidth * 100.0) >= minFrameWidth) korak = 100;
            else if ((frameWidth * 1000.0) >= minFrameWidth) korak = 1000;
            else if ((frameWidth * 10000.0) >= minFrameWidth) korak = 10000;
            else if ((frameWidth * 100000.0) >= minFrameWidth) korak = 100000;
            else if ((frameWidth * 1000000.0) >= minFrameWidth) korak = 1000000;

            var visRangeF = {
                min: this.timeToFrame(visRange.min, num, den),
                max: this.timeToFrame(visRange.max, num, den)
            };

            var st = Math.floor(visRangeF.min / korak);
            var ed = Math.floor(visRangeF.max / korak);

            st--;
            ed++;

            st = st * korak;
            ed = ed * korak;

            for (var i = st; i <= ed; i += korak) {
                var px = this.frameToWindow(i, w, visRangeF);

                if ((i % (korak * 10)) == 0) {
                    ctx.beginPath();
                    ctx.moveTo(px, 0);
                    ctx.lineTo(px, 15);
                    ctx.stroke();
                    var tcWidth = this.ctx.measureText(`${i}`).width;
                    ctx.fillText(i, px - tcWidth / 2, 25);
                    // swprintf_s(buffer, 256, L"%d", i);
                    // dc.TextOut(px + ilinew / 2, 10 + h1 + 2, buffer);
                }
                {
                    ctx.beginPath();
                    ctx.moveTo(px, 0);
                    ctx.lineTo(px, 5);
                    ctx.stroke();
                }
            }


            ctx.strokeStyle = oldStrokeStyle;
            ctx.fillStyle = oldFillStyle;
        },
        drawCurrentFrame(ctx, w, h, visRange, currentTime) {
            if (currentTime >= visRange.min && currentTime <= visRange.max) {
                //console.log(`drawCurrentFrame ${this.fpsNum} / ${this.fpsDen}`);
                var frameWidth = (w * this.fpsDen) / ((visRange.max - visRange.min) * this.fpsNum);
                var xpos = this.timeToPix(currentTime);
                if (frameWidth < 1.0) {
                    var oldStrokeStyle = ctx.strokeStyle;
                    ctx.strokeStyle = "#FF0000";
                    ctx.beginPath();
                    ctx.moveTo(xpos, 0);
                    ctx.lineTo(xpos, h - this.scrollbar.size);
                    ctx.stroke();
                    ctx.strokeStyle = oldStrokeStyle;
                }
                else {
                    var oldFillStyle = ctx.fillStyle;
                    ctx.fillStyle = "#FF0000";
                    ctx.fillRect(xpos - frameWidth / 2, 0, frameWidth, h - this.scrollbar.size);
                    ctx.fillStyle = oldFillStyle;
                }
            }
        },
        drawTCText(ctx, w, h, visRange, currentTime) {
            var oldStrokeStyle = ctx.strokeStyle;
            var oldFillStyle = ctx.fillStyle;

            ctx.strokeStyle = "#FFFFFF";
            ctx.fillStyle = "#FFFFFF";

            var num = this.fpsNum;
            var den = this.fpsDen;

            // if (den == 1001) {
            //     den = 1;
            //     num = Math.floor(num / 1000);
            // }

            var currentTC = this.timecode.timeCode2Str(this.timeToFrame(currentTime, num, den), true, true);
            var minTC = this.timecode.timeCode2Str(this.timeToFrame(visRange.min, num, den), true, true);
            var maxTC = this.timecode.timeCode2Str(this.timeToFrame(visRange.max, num, den), true, true);

            ctx.font = "16px sans-serif";
            var tcWidth = this.ctx.measureText("00:00:00:00").width;

            // left
            ctx.fillText(minTC, 5, 95);

            // right
            ctx.fillText(maxTC, w - tcWidth - 5, 95);

            var ps = `x${this.playbackSpeed}`;
            var psWidth = this.ctx.measureText(ps).width;
            ctx.fillText(ps, w / 2 - psWidth / 2, 65);

            // current
            ctx.font = "30px sans-serif";
            var tcWidth = this.ctx.measureText("00:00:00:00").width;
            ctx.fillText(currentTC, w / 2 - tcWidth / 2, 95);

            ctx.strokeStyle = oldStrokeStyle;
            ctx.fillStyle = oldFillStyle;
        },
        drawSelection(ctx, w, h, visRange, currentSelection) {
            //console.log(currentSelection);
            if (currentSelection.start == -1 || currentSelection.end == -1)
                return;
            var num = this.fpsNum;
            var den = this.fpsDen;
            var visRangeF = {
                min: this.timeToFrame(visRange.min, num, den),
                max: this.timeToFrame(visRange.max, num, den)
            };

            var st = this.frameToWindow(currentSelection.start, w, visRangeF);
            var ed = this.frameToWindow(currentSelection.end, w, visRangeF);
            //console.log({ st, ed });
            if (st < 0) st = 0;
            if (ed >= w) ed = w - 1;
            var oldFillStyle = ctx.fillStyle;
            ctx.fillStyle = "#6060FF80";
            ctx.fillRect(st, 0, ed - st, h - this.scrollbar.size);
            ctx.fillStyle = oldFillStyle;
        },
        drawCut(ctx, w, h, visRange, currentCut) {
            //console.log(currentSelection);
            if (currentCut.start == -1 || currentCut.end == -1)
                return;
            var num = this.fpsNum;
            var den = this.fpsDen;
            var visRangeF = {
                min: this.timeToFrame(visRange.min, num, den),
                max: this.timeToFrame(visRange.max, num, den)
            };

            var st = this.frameToWindow(currentCut.start, w, visRangeF);
            var ed = this.frameToWindow(currentCut.end, w, visRangeF);
            //console.log({ st, ed });
            if (st < 0) st = 0;
            if (ed >= w) ed = w - 1;
            var oldFillStyle = ctx.fillStyle;
            ctx.fillStyle = "#C03030";
            ctx.fillRect(st, 0, ed - st, this.scrollbar.size + 10);
            ctx.fillStyle = oldFillStyle;
        },


    }

}
</script>