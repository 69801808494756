<template>
    <div class="position-absolute"  :style="position">
        <CCard style="height:100%;" color="dark">
            <CCardHeader class="fixheader">
                <CRow class="buttonw">
                    <CCol v-if="loaded" sm="auto">
                        <CButton size="sm" color="success" @click="onSolo">Solo</CButton>
                    </CCol>
                    <CCol v-if="loaded" sm="auto">
                        <CButton size="sm" color="success" @click="onRotate">Rot</CButton>
                    </CCol>
                    <CCol v-if="(volume > 0) && loaded">
                        <CIcon :icon="cilHeadphones" class="align-middle" size="xl"/>
                    </CCol>
                    <CCol v-if="loading" sm="3" class="ms-auto text-end">
                        <!-- <CSpinner v-if="loading" /> -->
                        <CProgress v-if="loading" :value="progress">{{ getProgress() }}</CProgress>
                    </CCol>
                </CRow>
            </CCardHeader>
            <CCardBody class="">
                <CRow class="thecontent">
                    <CCol class="">
                        <video ref="video" class="c3dvid" @loadedmetadata="loadedMetadata" :style="rotate"></video>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <!-- <CContainer style="height:100%;">
            <CRow>
                <CCol v-if="loading" sm="auto" class="xpx-1" >
                    <CSpinner/>
                </CCol>
                <CCol v-if="loaded" sm="auto" class="px-1" >
                    <CButton size="sm" color="success" @click="onSolo">Solo</CButton>
                </CCol>
                <CCol v-if="loaded" sm="auto" class="px-1" >
                    <CButton size="sm" color="success" @click="onRotate">Rot</CButton>
                </CCol>
                <CCol v-if="(volume > 0) && loaded">
                    <CIcon :icon="cilHeadphones" size="xl"/>
                </CCol>
            </CRow>    
            <CRow class="mt-1 flex-column h-100">
                <CCol class="flex-fill">
                    <video ref="video" class="c3dvid" @loadedmetadata="loadedMetadata" :style="rotate"></video>
                </CCol>
            </CRow>
        </CContainer> -->
    </div>
</template>

<script>
import secureImageLoad from "@/utils/secureImageLoad.js"
//import { nextTick } from '@vue/runtime-core'
import { nextTick } from 'vue';
import { CIcon } from '@coreui/icons-vue';
import { cilHeadphones } from '@coreui/icons';

import Timecode from "@/utils/timecode.js";
import { CButton } from "@coreui/vue-pro";

export default {
    name: "CentroidVideoPlayer",
    components: {
        CIcon
    },
    props: {
        vindex:Number
    },
    emits: ['solo', 'rotated', 'currentTime', 'loopEnd', 'duration'],
    data() {
        return {
            cilHeadphones,
            loaded: false,
            loading: false,
            duration: 0,
            currentTime: 0,
            cid: -1,
            tcstr: "00:00:00:00",
            fpsN: 24,
            fpsD: 1,
            syncOffset: 0,
            tc: null,
            timer: null,
            speed: 1,
            loop: false,
            play_range: null,
            tmlntc: null,
            timeOffsetMS: 0,
            angle: 0,
            volume: 1,
            posx: 0,
            posy: 0,
            sizex: 160,
            sizey: 90,
            progress:0
        }
    },
    computed: {
        rotate() {
            if (this.angle == 90 || this.angle == 270)
                return { transform: 'rotate(' + this.angle + 'deg) scale(176%)', transition: '0.5s linear' };
            else
                return { transform: 'rotate(' + this.angle + 'deg) scale(100%)', transition: '0.5s linear'};
        },
        position() { 
            return {
                left: this.posx + 'px',
                top: this.posy + 'px',
                width: this.sizex + 'px',
                height: this.sizey + 'px',
                transition: '0.5s linear'
            };
            
            //var pos = `left: ${this.posx}px; top: ${this.posy}px; width: ${this.sizex}px; height: ${this.sizey}px;`
            //return pos;
        }
    },
    mounted() {
        //this.$refs.video.addEventListener('loadedmetadata', this.loadedMetadata);
    },
    methods:
    {
        getProgress() {
            return Math.floor(this.progress * 100) / 100; 
        },
        setPositionAndSize(x,y,w,h) { 
            this.posx = x;
            this.posy = y;
            this.sizex = w;
            this.sizey = h;
        },
        onSolo() { 
            this.$emit("solo", this.cid);
            //this.$refs.video.volume = 0;
        },
        isVertical() {  
            if (this.angle == 90 || this.angle == 270)
                return true;
            else
                return false;
        },
        onRotate() {
            if (this.angle == 0)
            {
                this.angle = 90;
                this.$emit("rotated", this.cid);
                return;
            }
            else if (this.angle == 90)
            {
                this.angle = 180;
                this.$emit("rotated", this.cid);
                return;
            }
            else if (this.angle == 180)
            {
                this.angle = 270;
                this.$emit("rotated", this.cid);
                return;
            }
            else if (this.angle == 270)
            {
                this.angle = 0;
                this.$emit("rotated", this.cid);
                return;
            }
        },
        setVolume(vol)
        {
            if (this.loaded) {
                this.volume = vol;
                this.$refs.video.volume = vol;
            }
        },

        async loadVideo(pid, tid, cid, media, tmlntc) {
            if (this.timer != null) {
                clearInterval(this.timer);
                this.timer = null;
            }
            this.tmlntc = tmlntc;
            this.cid = cid;
            //console.log(media);
            this.tcstr = media.tc;
            this.fpsN = media.fpsN;
            this.fpsD = media.fpsD;
            this.loading = true;
            this.loaded = false;
            var req = this.purl + `/api/resources/project/${pid}/take/${tid}/cam/${cid}`;
            secureImageLoad.video(this.$refs.video, req, async (res) => {
                //console.dir(this.$refs.video);
                this.loaded = res;
                this.loading = false;
                //console.log("this.loaded=", this.loaded);
                this.tc = new Timecode;
                this.tc.initFromStringAndFPS(this.tcstr, this.fpsN, this.fpsD);
                if (this.cid == 1)
                    this.$refs.video.onpause = this.onPause;

                //console.log(`DF ${this.tc.getFrames()}, 30 FPS: ${tmlntc.getFrames()}`);
                //console.log(this.tc);
                //console.log(tmlntc);

                this.progress = 0;
                var takeStartMs = (tmlntc.getFrames() * tmlntc.mFpsd) / tmlntc.mFpsn;
                var videoStartMs = (this.tc.getFrames() * this.tc.mFpsd) / this.tc.mFpsn;
                //console.log(`take ${takeStartMs}, video: ${videoStartMs}`);

                this.timeOffsetMS = takeStartMs - videoStartMs;
                //console.log("diff = " + this.timeOffsetMS);
                this.$refs.video.volume = this.volume;
            }, async (progress) => {
                //console.log("P: ", progress);
                this.progress = progress;
            });
        },
        seek(epos) {
            if (this.loaded) {
                //this.$refs.video.currentTime = epos.currentTime;// + 2 / 24;
                var dframe = epos.frame - this.tmlntc.getFrames();
                var msec = (dframe * this.fpsD) / this.fpsN + this.fpsD / (2 * this.fpsN); // add half frame time
                msec += this.timeOffsetMS;
                if (msec < 0) msec = 0;

                //console.log(`${epos}, ${msec}, ${dframe}, ${this.fpsN}, ${this.fpsD}`);
                this.$refs.video.currentTime = msec;
            }
        },
        getPlaybackSpeed() {
            return this.speed;
        },
        playRange(st, ed, bLoop) {
            if (this.tc != null) {
                //console.log("playRange TC = ", this.tc);
                var dframe = ed.frame - this.tmlntc.getFrames();
                var msec = (dframe * this.fpsD) / this.fpsN + this.fpsD / (1 * this.fpsN); // add half frame time
                msec += this.timeOffsetMS;
                if (msec < 0) msec = 0;
                this.play_range = {
                    start: st,
                    end: ed,
                    loop: bLoop,
                    endTime: msec
                };

                this.togglePlay(bLoop);
            }
        },
        togglePlay(bLoop) {
            if (this.loaded) {
                this.loop = bLoop;
                
                if (this.$refs.video.paused) {
                    this.speed = 1;
                    this.$refs.video.playbackRate = this.speed;
                    this.$refs.video.play();
                    //console.log(this.$refs.video.playbackRate);
                    if (this.cid == 1) {
                        if (this.timer == null) {
                            this.timer = setInterval(this.timerFunc, 30);
                        }
                    }
                }
                else {
                    this.$refs.video.pause();
                    this.speed = 0;
                    delete this.play_range;
                    this.play_range = null;

                    if (this.timer != null) {
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }
            }
        },
        timerFunc() {
            if (this.cid == 1) {
                //console.log("timerFunc");
                this.currentTime = this.$refs.video.currentTime;
                this.$emit("currentTime", this.cid, this.currentTime - this.timeOffsetMS);

                if (this.play_range != null) {
                    if (this.currentTime >= this.play_range.endTime) {
                        this.$emit("loopEnd", this.play_range);
                    }
                }
            }
        },
        play(rate) {
            if (this.loaded) {
                if (rate != undefined) {
                    this.speed = rate;
                    this.$refs.video.playbackRate = this.speed;
                }
                this.$refs.video.play();
                //console.log(this.$refs.video.playbackRate);
                if (this.cid == 1) {
                    if (this.timer == null) {
                        //console.log("setInterval...");
                        this.timer = setInterval(this.timerFunc, 30);
                    }
                }
            }
        },
        pause() {
            if (this.loaded) {
                this.$refs.video.pause();
                this.speed = 0;
                if (this.timer != null) {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }
        },
        modifySpeed(s) {
            this.speed += s;
            if (this.speed < 0) this.speed = 0;
            if (this.speed > 8) this.speed = 8;
            //this.$refs.video.playbackRate = rate;
            if (this.speed == 0)
                this.pause();
            else
                this.play(this.speed);
        },
        onPause() {
            //console.log("onPause");
            this.currentTime = this.$refs.video.currentTime;
            this.$emit("currentTime", { cid: this.cid, currentTime: this.currentTime - this.timeOffsetMS });
        },
        loadedMetadata(e) {
            this.duration = e.target.duration;
            this.$emit("duration", { cid: this.cid, duration: this.duration });
            //console.log("duration = " + this.duration);
        },
        onTimeUpdate(e) {
            this.currentTime = e.target.currentTime;// + 2 / 24;
            this.$emit("currentTime", { cid: this.cid, currentTime: this.currentTime - this.timeOffsetMS });
        }
    }
}
//    transform: rotate(90deg);
</script>

<style scoped>
.c3dvid {
    width: 97%;
    height: 100%;
    object-fit: contain;
    position: absolute; /* magic sauce */
}

.mymargins {
    padding: 10px;
}

.buttonw > div.col-sm-auto {
    padding: 0 4px;
    /* margin: 0 ; */
}

.fixheader {
    padding: 8px 0 8px 24px !important;
}

.thecontent {
  height: 100%;
  position: relative; /* magic sauce II */
}

</style>