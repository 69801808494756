import { createStore } from 'vuex'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    userRole:"User",
    isSuperAdmin: false,
    isAdmin: false,
    isTechnician: false,
    isAccountant: false,
    isClientAdmin: false,
    isClientProducer: false,
    isClientEditor: false
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setUserRole(state, r) {
      state.userRole = r;
    },
    setToken(state, t) {
      state.token = t;
    },

    setIsSuperAdmin(state, t) {
      state.isSuperAdmin = t;
    },
    setIsAdmin(state, t) {
      state.isAdmin = t;
    },
    setIsClientAdmin(state, t) {
      state.isClientAdmin = t;
    },
    setIsAccountant(state, t) {
      state.isAccountant = t;
    },
    setIsTechnician(state, t) {
      state.isTechnician = t;
    },
    setIsClientProducer(state, t) {
      state.isClientProducer = t;
    },
    setIsClientEditor(state, t) {
      state.isClientEditor = t;
    },
  },
  actions: {},
  modules: {},
})
