<template>
    <vuetable ref="tblcuts" 
        :showHeader=false
        :api-mode="false" 
        :data="cuts" 
        track-by="id" 
        :fields="tbl.fields" 
        :css="tbl.css.table"
        @vuetable:cell-clicked="onCellClicked"
        @vuetable:cell-rightclicked="onRightClick"        
        :rowClass="onRowClass"
    >
    </vuetable>
</template>

<script>
import { defineAsyncComponent, markRaw, nextTick } from 'vue';
import axios from 'axios';

//import Vuetable from 'vue3-vuetable';
import Vuetable from '@/components/vue3-vuetable/src/components/Vuetable.vue';
//import CutTblActions from './CutTblActions.vue';
const CutTblActions = () => import('./CutTblActions.vue');
const VuetableFieldCheckbox = () => import('@/components/vue3-vuetable/src/components/VuetableFieldCheckbox.vue');

import Timecode from "@/utils/timecode.js"

export default {
    name: "TakesTblDetails",
    components: {
        'vuetable': Vuetable,
        //VuetableFieldCheckbox,
    },
    inject: ["doSelectCut", "showContextMenu"],
    provide: function () {
        return {
            doChangePriority: this.onChangePriority
        }    
    },
    props: {
        rowData: {
            type: Object,
            required: true
        },
        rowIndex: {
            type: Number
        },
        options: {
            type: Object,
        }
    },
    data() {
        return {
            tbl: {
                data: [],
                fields: [
                    {
                        name: markRaw(defineAsyncComponent(VuetableFieldCheckbox)),
                        width: "5%",
                    },
                    {
                        title: "Range",
                        name: 'id',
                        width: "55%",
                        formatter: this.makeRange,
                    },
                    {
                        title: "Len",
                        name: 'id',
                        width: "15%",
                        formatter: this.calcLen,
                        dataClass:'text-right'
                    },
                    {
                        title: "Type",
                        name: 'type',
                        width: "15%",
                        formatter: this.makeType,
                    },
                    {
                        title: "Actions",
                        name: markRaw(defineAsyncComponent(CutTblActions))
                    },
                ],
                css: {
                    table: {
                        tableClass: "table table-light table-striped table-hover table-sm align-middle",
                        ascendingIcon: "glyphicon glyphicon-chevron-up",
                        descendingIcon: "glyphicon glyphicon-chevron-down",
                        handleIcon: "glyphicon glyphicon-menu-hamburger",
                        renderIcon: function (classes, options) {
                            return `<span class="${classes.join(' ')}"></span>`
                        }
                    }
                },

            },
            tc: null,
            cuts: [],
            chooseCutId:0,
        }
    },
    mounted() {
        // console.log(this.options);    
        console.log("Details... ");
        console.log(this.rowData);
        // this.options.timecode.mFpsn & mFpsd
        this.tc = new Timecode();
        this.tc.initFromString(this.rowData.timecode);
        this.fetchCuts();
    },
    watch: {
        "options.orderId": function (oldVal, newVal) {
            // console.log("old:"); console.log(oldVal);
            // console.log("new:"); console.log(newVal);
            this.fetchCuts();
        },
        "options.priorityFilter": async function (oldVal, newVal) {
            // console.log("old:"); console.log(oldVal);
            // console.log("new:"); console.log(newVal);
            await nextTick();
            this.fetchCuts();
        }
    },
    methods: {
        calcLen(cut, p) {
            //console.log(this.options);

            if (this.options.timecode != null) {
                var fpsn = this.options.timecode.mFpsn;
                var fpsd = this.options.timecode.mFpsd;
                var lensec = Math.ceil(((cut.endFrame - cut.startFrame) * fpsd) / fpsn);
                return `<strong>${lensec}</strong>`;
            }
            else  return `<strong>---</strong>`
        },
        makeRange(cut, p) {

            var st = this.tc.timeCode2Str(cut.startFrame, true, true);
            var ed = this.tc.timeCode2Str(cut.endFrame, true, true);
            return `<strong>${st} - ${ed}</strong>`;
        },
        makeType(cut, p) { 
            //console.log(cut);
            if (cut.type == 1) return "<strong>BODY</strong>";
            else if (cut.type == 2) return "<strong>FACE</strong>";
        },
        getSelectedCuts() { 
            return this.$refs.tblcuts.selectedTo;
        },
        fetchCuts() {
            // console.log(`fetchCuts ${this.rowData.id}/${this.options.orderId}?priority=${this.options.priorityFilter}`);

            axios.get(this.purl + `/api/trimmer/takes/cuts/${this.rowData.id}/${this.options.orderId}?priority=${this.options.priorityFilter}`)
                .then(result => {
                    // console.log(result.data);
                    this.cuts = result.data.cuts;
                }).catch(e => {
                    console.log(e);
                });
        },
        onCellClicked(data, field, event)
        {
            var ret = this.doSelectCut(data.data);
            if (ret)
            {
                this.chooseCutId = data.data.id;
            }
        },
        onRowClass(item, index) {
            if (item.id == this.chooseCutId)
                return 'table-success';
            else
                return '';
        },
        onRightClick(data, index, field, event) {
            data.event.preventDefault();
            console.log(data);
            this.showContextMenu({ id: data.data.id, takeId:data.data.takeId, x: data.event.pageX, y: data.event.pageY });
        },
        onChangePriority(item) {
            item.cut.priority = item.priority;
            axios({
                method: "post",
                url: this.purl + "/api/trimmer/cuts/priority",
                data: {
                    cutId: item.cut.id,
                    priority: item.priority,
                },
                headers: { "Content-Type": "application/json" },
            }).then((response) => {
                //handle success
                // console.log("success...");
                // console.log(response.data);

            }).catch((response) => {
                //handle error
                console.log(response);
            });
        }
    }
}
</script>

<style scoped></style>