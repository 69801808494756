<template>
    <th v-if="isHeader">Action</th>
    <td v-else>
        <CButton class="align-middle" color="primary" @click="loadTake" size="sm">Load</CButton>
    </td>
</template>

<script>
import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin'

export default {
    mixins: [VuetableFieldMixin],
    inject: ["doLoadTake"],
    methods: {
        loadTake() {
            //console.log(this.rowData);
            this.doLoadTake(this.rowData);
        }
    }
}
</script>
<style scoped lang="scss">
.btn-sm {
    --cui-btn-font-size: 0.875em;
}
</style>