export const centroidSmall = [
    '8.4667 1.77',
    `
<title>centroid-small</title>
 <g transform="matrix(.047108 0 0 .04706 -.74424 -4.2967)">
  <path d="m162.39 95.011q0-0.83241 0.60539-1.4378 0.62052-0.60539 1.4681-0.60539 0.87782 0 1.4529 0.59026 0.59025 0.57512 0.59025 1.4529 0 0.87782-0.59025 1.4832-0.59026 0.59026-1.4529 0.59026-0.84755 0-1.4681-0.60539-0.60539-0.62053-0.60539-1.4681z" fill="#f00"/>
  <g fill="#fff">
   <path d="m27.769 103.9q-0.76933-0.83241-1.8002-1.2713-1.0155-0.43891-2.2003-0.43891-2.0464 0-3.2774 1.3016-1.2309 1.3016-1.2309 3.4507 0 2.2399 1.2309 3.5264 1.2463 1.2713 3.4312 1.2713 1.2155 0 2.1695-0.43891 0.95397-0.45404 1.6771-1.3621v3.7837q-0.9232 0.51458-1.9849 0.77187-1.0463 0.2573-2.2311 0.2573-3.4312 0-5.6007-2.1491-2.1541-2.1491-2.1541-5.5696 0-3.4659 2.2311-5.5847 2.2465-2.134 5.8623-2.134 1.1386 0 2.0926 0.22702 0.95397 0.21189 1.7849 0.66593z"/>
   <path d="m54.746 107.48h-11.472q0.12108 2.0129 1.1956 3.148 1.0746 1.12 2.8605 1.12 1.5892 0 2.6486-0.66593 1.0594-0.66593 1.8464-2.1491l2.7697 1.5438q-1.2108 2.1643-2.9967 3.2237-1.7859 1.0594-4.1923 1.0594-3.4507 0-5.4939-2.1037-2.0432-2.1037-2.0432-5.615 0-3.375 2.1037-5.5393 2.1189-2.1794 5.4334-2.1794 3.4659 0 5.4031 1.9827 1.9372 1.9826 1.9372 5.5545zm-3.4053-2.2097q-0.19675-1.5438-1.1805-2.3459-0.98376-0.81728-2.694-0.81728-1.6194 0-2.6032 0.78701-0.98376 0.78701-1.3773 2.3762z"/>
   <path d="m67.562 114.17v-14.355h2.8237v2.3471q0.89971-1.484 2.1178-2.1654 1.2181-0.68141 2.9621-0.68141 1.4672 0 2.4915 0.51485 1.0381 0.4997 1.6195 1.4991 0.34604 0.59055 0.4983 1.378 0.15226 0.78741 0.15226 2.7559v8.7069h-3.0313v-7.3744q0-2.8316-0.63672-3.7553-0.62288-0.93884-2.2285-0.93884-1.052 0-1.8825 0.42398-0.81666 0.424-1.2873 1.1963-0.3322 0.51485-0.47062 1.3326-0.12458 0.80255-0.12458 2.5742v6.5416z"/>
   <path d="m94.555 114.17v-11.472h-2.5124v-2.8756h2.5124v-4.5452h3.2842v4.5452h2.5122v2.8756h-2.5122v11.472z"/>
   <path d="m116.7 102.1q0.52972-1.4227 1.3924-2.1037 0.87782-0.68106 2.1643-0.68106 0.6962 0 1.3621 0.16648 0.68107 0.16648 1.3319 0.48431l-1.1805 2.7848q-0.42377-0.3027-0.92322-0.43891-0.48431-0.15135-1.0897-0.15135-1.6346 0-2.3459 1.3167-0.71134 1.3016-0.71134 4.3891v6.2961h-3.2842v-14.378h3.2842z"/>
   <path d="m146.29 107.04q0-1.9978-1.2865-3.375-1.2864-1.3924-3.148-1.3924-1.8464 0-3.148 1.3924-1.2865 1.3773-1.2865 3.375 0 1.9978 1.2865 3.3902 1.3016 1.3773 3.148 1.3773t3.1329-1.3773q1.3016-1.3924 1.3016-3.3902zm-12.335-0.0303q0-1.574 0.59026-2.9664 0.60539-1.4075 1.7254-2.4972 1.1048-1.0746 2.5426-1.6497 1.4378-0.57512 3.0118-0.57512 1.6194 0 3.0421 0.57512 1.4378 0.57512 2.5729 1.6951 1.1351 1.1048 1.7254 2.4972 0.59026 1.3924 0.59026 2.921 0 1.5892-0.59026 2.9815-0.57512 1.3924-1.6951 2.467-1.1805 1.1351-2.6183 1.7102-1.4378 0.59026-3.027 0.59026-1.5589 0-2.9967-0.59026-1.4378-0.57512-2.5578-1.68-1.1351-1.12-1.7254-2.5124-0.59026-1.3924-0.59026-2.9664z"/>
   <path d="m162.83 114.17v-14.348h3.2842v14.348z"/>
   <path d="m192.52 106.95c0-1.4824-0.40463-2.6371-1.2139-3.464-0.79754-0.82693-1.9176-1.2404-3.3602-1.2404-1.4426 0-2.5802 0.42859-3.413 1.2858-0.82099 0.8471-1.2315 2.027-1.2315 3.5396 0 1.5228 0.39877 2.6976 1.1963 3.5245 0.79753 0.82693 1.9235 1.2404 3.3778 1.2404 1.384 0 2.504-0.44371 3.3602-1.3312 0.85618-0.89753 1.2843-2.0825 1.2843-3.5548zm-0.45858-15.644h3.4659v22.864h-3.4659v-1.8616c-0.61758 0.82737-1.3416 1.4428-2.1722 1.8464-0.81991 0.4036-1.7623 0.6054-2.827 0.6054-2.1402 0-3.828-0.69116-5.0631-2.0735-1.2245-1.3924-1.8368-3.2943-1.8368-5.7058 0-2.2904 0.62823-4.1368 1.8847-5.5393 1.2565-1.4126 2.8963-2.1189 4.9194-2.1189 1.2352 0 2.2893 0.2472 3.1625 0.74161 0.88379 0.48431 1.528 1.1856 1.9326 2.1037z"/>
  </g>
  <g fill="#fff">
   <path d="m18.077 125.72v-6.0371h2.0775l1.2474 4.118 1.2334-4.118h2.0822v6.0371h-1.2896v-4.7522l-1.3647 4.7522h-1.3365l-1.36-4.7522v4.7522z"/>
   <path d="m32.92 122.74q0-0.92204 0.29894-1.5477 0.22309-0.46101 0.6068-0.82735 0.38817-0.36635 0.84773-0.54334 0.61126-0.23875 1.4099-0.23875 1.4456 0 2.3112 0.82737 0.87004 0.82735 0.87004 2.301 0 1.4613-0.86112 2.2886-0.86112 0.82325-2.3023 0.82325-1.459 0-2.3201-0.81913-0.86112-0.82325-0.86112-2.2639zm1.3608-0.0412q0 1.025 0.5131 1.5559 0.5131 0.52688 1.3028 0.52688 0.78973 0 1.2939-0.52276 0.50864-0.52688 0.50864-1.5765 0-1.0373-0.49525-1.5477-0.49079-0.51042-1.3073-0.51042t-1.3162 0.51864q-0.49972 0.51452-0.49972 1.5559z"/>
   <path d="m48.94 125.72v-5.0158h-1.9361v-1.0213h5.1851v1.0213h-1.9316v5.0158z"/>
   <path d="m60.013 125.72v-6.0371h1.3361v6.0371z"/>
   <path d="m69.523 122.74q0-0.92245 0.30282-1.5484 0.22599-0.46121 0.61469-0.82772 0.39322-0.36651 0.85876-0.54358 0.61921-0.23885 1.4282-0.23885 1.4644 0 2.3412 0.82773 0.88136 0.82772 0.88136 2.302 0 1.4619-0.87232 2.2896-0.87232 0.82361-2.3322 0.82361-1.478 0-2.3503-0.81949-0.87232-0.82361-0.87232-2.2649zm1.3785-0.0412q0 1.0254 0.51977 1.5566 0.51977 0.52711 1.3198 0.52711t1.3107-0.52299q0.51525-0.52711 0.51525-1.5772 0-1.0378-0.50169-1.5484-0.49718-0.51064-1.3243-0.51064-0.82712 0-1.3333 0.51887-0.50622 0.51475-0.50622 1.5566z"/>
   <path d="m84.157 125.72v-6.0319h1.3086l2.7263 4.0281v-4.0281h1.2495v6.0319h-1.3495l-2.6854-3.9335v3.9335z"/>
   <path d="m111.65 123.5 1.2494 0.37474q-0.28732 0.98833-0.95772 1.4702-0.66604 0.47769-1.6934 0.47769-1.2712 0-2.0896-0.81949-0.81842-0.82361-0.81842-2.2485 0-1.5072 0.82276-2.3391 0.82277-0.83596 2.1636-0.83596 1.171 0 1.9024 0.65477 0.43535 0.3871 0.65299 1.1119l-1.2755 0.28827q-0.11318-0.46946-0.4745-0.74125-0.35697-0.27179-0.87066-0.27179-0.70959 0-1.1536 0.48181-0.43969 0.48181-0.43969 1.5607 0 1.1448 0.43532 1.6308 0.43534 0.48593 1.1319 0.48593 0.51369 0 0.88372-0.30885 0.37002-0.30886 0.5311-0.97186z"/>
   <path d="m126.98 125.72h-1.3906l-0.55276-1.3707h-2.5307l-0.52254 1.3707h-1.356l2.4659-6.0343h1.3517zm-2.3536-2.3874-0.87233-2.2392-0.85508 2.2392z"/>
   <path d="m135 125.72v-6.0357h2.1272q1.2091 0 1.5763 0.0906 0.56426 0.13587 0.94493 0.59287 0.38065 0.45288 0.38065 1.1734 0 0.5558-0.21944 0.93458-0.21943 0.37878-0.55979 0.59699-0.33587 0.21408-0.68517 0.28407-0.47469 0.0865-1.3748 0.0865h-0.8643v2.2768zm1.3256-5.0147v1.7127h0.72548q0.78369 0 1.0479-0.0948 0.26422-0.0948 0.412-0.29644 0.15227-0.20174 0.15227-0.46934 0-0.32938-0.21048-0.54347-0.21049-0.21409-0.53293-0.26761-0.23734-0.0412-0.95386-0.0412z"/>
   <path d="m149.74 125.72v-5.0158h-1.9808v-1.0213h5.3049v1.0213h-1.9762v5.0158z"/>
   <path d="m161.16 119.68h1.3067v3.2689q0 0.77813 0.0487 1.0087 0.0839 0.37054 0.39733 0.59697 0.31784 0.22232 0.86523 0.22232 0.55624 0 0.83876-0.20996 0.28254-0.2141 0.33993-0.52288 0.0574-0.30878 0.0574-1.0252v-3.3389h1.3067v3.1701q0 1.0869-0.10601 1.5357-0.10602 0.44875-0.3929 0.75754-0.28252 0.30877-0.75929 0.49404-0.47677 0.18115-1.2449 0.18115-0.92706 0-1.4082-0.19762-0.47677-0.20173-0.75488-0.51874-0.27812-0.32113-0.3664-0.67108-0.12802-0.51875-0.12802-1.5316z"/>
   <path d="m174.86 125.72v-6.0348h2.7873q1.0514 0 1.5257 0.16466 0.47873 0.16054 0.76506 0.57632 0.28635 0.41576 0.28635 0.95091 0 0.67922-0.43398 1.1238-0.43399 0.44046-1.2975 0.55573 0.4295 0.23051 0.7069 0.50633 0.28186 0.2758 0.75611 0.97973l0.80086 1.1773h-1.5838l-0.95745-1.3132q-0.51003-0.70393-0.69795-0.88505-0.18791-0.18524-0.39818-0.25111-0.21029-0.07-0.66665-0.07h-0.26844v2.5193zm1.3243-3.4826h0.97982q0.95298 0 1.1901-0.0741 0.23714-0.0741 0.37135-0.25522 0.13422-0.18113 0.13422-0.45282 0-0.30462-0.17895-0.48987-0.17448-0.18936-0.49662-0.23875-0.16107-0.0206-0.96641-0.0206h-1.0335z"/>
   <path d="m188.71 125.72v-6.034h4.9093v1.0207h-3.5725v1.488h3.3241v1.0166h-3.3241v1.4919h3.6989v1.0166z"/>
  </g>
 </g>
`,
]
