"use strict";

export default class CTimeCode {
    constructor () {
        this.mFrames = 0;
        this.mFpsn = 0;
        this.mFpsd = 0;
        this.bDropFrame = false;
        //this.InitFromString(str, fpsn, fpsd, bDrop);
    }

	init(frame, fpsn, fpsd, bDrop) {
        this.mFrames = frame;
        this.mFpsn = fpsn;
        this.mFpsd = fpsd;
        this.bDropFrame = bDrop;
    }

	init(hh, mm, ss, ff, fpsn, fpsd, bDrop) {
        this.bDropFrame = bDrop;
        this.mFpsd = fpsd;
        this.mFpsn = fpsn;

		var iHH = hh;
        var iMM = mm;
        var iSS = ss;
        var iFF = ff;
        // 		if (this->fps > 29.0f && this->fps < 30.0f)
        // 		{
        // 			long second_30fps = 30;
        // 			long minute_30fps = 60 * second_30fps;
        // 			long hour_30fps = 60 * minute_30fps;
        // 			int l = iHH * hour_30fps + iMM * minute_30fps + iSS * second_30fps + iFF;
        // 			m_Frames = l;
        // 
        // 			// fix it now - dropframe
        // 			if (bDropFrame)
        // 			{
        // 				m_Frames = l;
        // 				m_Frames -= 2*(l/minute_30fps);
        // 				m_Frames += 2*(l/(10*minute_30fps));
        // 			}
        // 		}
        // 		else if (this->fps > 23.0f && this->fps < 24.0f)
        // 		{
        // 			long second_24fps = 24;
        // 			long minute_24fps = 60 * second_24fps;
        // 			long hour_24fps = 60 * minute_24fps;
        // 			int l = iHH * hour_24fps + iMM * minute_24fps + iSS * second_24fps + iFF;
        // 			
        // 			m_Frames = l;
        // 			// fix it now - dropframe
        // 			if (bDropFrame)
        // 			{
        // 				m_Frames = l;
        // 				m_Frames -= 2*(l/minute_24fps);
        // 				m_Frames += 2*(l/(10*minute_24fps));
        // 			}
        //		}
        //		else
        {
            this.mFrames = Math.floor((( (iHH * 60 + iMM) * 60 + iSS) * fpsn) / fpsd) + iFF;            
        // 			m_Frames = hh * fpsn * 60 * 60 / fpsd +
        // 				mm * fpsn * 60 / fpsd +
        // 				ss * fpsn / fpsd +
        // 				ff;

        }

        return true;
    }

    InitFromString(strTimeCode, fpsn, fpsd, bDrop) {
        console.log(strTimeCode);
        var strHH = strTimeCode.substring(0, 2);
		var iHH = parseInt(strHH, 10);

        var strMM = strTimeCode.substring(3, 2);
        var iMM = parseInt(strMM, 10);

        var strSS = strTimeCode.substring(6, 2);
        var iSS = parseInt(strSS, 10);

        var strFF = strTimeCode.substring(9, 2);
        var iFF = parseInt(strFF, 10);

        this.init(iHH, iMM, iSS, iFF, fpsn, fpsd, bDrop);

        return true;
    }

    initFromStringAndFPS(strTimeCode, fpsN, fpsD)
    {
        this.strTC = strTimeCode;
        var sHH = strTimeCode.substring(0, 2);
        var sMM = strTimeCode.substring(3, 5);
        var sSS = strTimeCode.substring(6, 8);
        var sFF = strTimeCode.substring(9, 11);
        var iHH = parseInt(sHH, 10);
        var iMM = parseInt(sMM, 10);
        var iSS = parseInt(sSS, 10);
        var iFF = parseInt(sFF, 10);
        this.mFpsn = fpsN;
        this.mFpsd = fpsD;
        if (this.mFpsn == 24 && this.mFpsd == 1) {
            this.bDropFrame = false;
            this.mFrames = ((iHH * 60 + iMM) * 60 + iSS) * 24 + iFF;
        }
        else if (this.mFpsn == 24000 && this.mFpsd == 1001) {
            this.bDropFrame = true;
            var second_24fps = 24;
            var minute_24fps = 60 * second_24fps;
            var hour_24fps = 60 * minute_24fps;
            var l = iHH * hour_24fps + iMM * minute_24fps + iSS * second_24fps + iFF;

            // fix it now - dropframe
            this.mFrames = l;
            this.mFrames -= 2 * (l / minute_24fps);
            this.mFrames += 2 * (l / (10 * minute_24fps));
        }
        else if (this.mFpsn == 25 && this.mFpsd == 1) { 
            this.bDropFrame = false;
            this.Frames = ((iHH * 60 + iMM) * 60 + iSS) * 25 + iFF;
        }
        else if (this.mFpsn == 30 && this.mFpsd == 1) {
            this.bDropFrame = false;
            var second_30fps = 30;
            var minute_30fps = 60 * second_30fps;
            var hour_30fps = 60 * minute_30fps;
            var l = iHH * hour_30fps + iMM * minute_30fps + iSS * second_30fps + iFF;
            this.mFrames = l;
            return true;
        }
        else if (this.mFpsn == 30000 && this.mFpsd == 1001) {
            this.bDropFrame = true;
            var second_30fps = 30;
            var minute_30fps = 60 * second_30fps;
            var hour_30fps = 60 * minute_30fps;
            var l = iHH * hour_30fps + iMM * minute_30fps + iSS * second_30fps + iFF;

            // fix it now - dropframe
            this.mFrames = l;
            this.mFrames -= 2 * (l / minute_30fps);
            this.mFrames += 2 * (l / (10 * minute_30fps));
        }
    }

    initFromString(strTimeCode)
    {
        this.strTC = strTimeCode;

        // 0123456789A
        // 00:00:00:00
        //debugger;
        var sHH = strTimeCode.substring(0, 2);
        var sMM = strTimeCode.substring(3, 5);
        var sSS = strTimeCode.substring(6, 8);
        var sFF = strTimeCode.substring(9, 11);
        var iHH = parseInt(sHH, 10);
        var iMM = parseInt(sMM, 10);
        var iSS = parseInt(sSS, 10);
        var iFF = parseInt(sFF, 10);

        var standard = "25";
        this.mFpsn = 25;
        this.mFpsd = 1;
        this.bDropFrame = false;
        var comma = strTimeCode.indexOf(',');
        if (comma != -1) standard = strTimeCode.substring(comma + 2);

        if (standard == 'FILM' || standard == '24') {
            this.mFpsn = 24;
            this.mFpsd = 1;
            this.bDropFrame = false;
            this.mFrames = ((iHH * 60 + iMM) * 60 + iSS) * 24 + iFF;
            return true;
        }
        if (standard == 'FILMDF' || standard == '23.98') {
            this.mFpsn = 2400;
            this.mFpsd = 1001;
            this.bDropFrame = true;
			var second_24fps = 24;
			var minute_24fps = 60 * second_24fps;
			var hour_24fps = 60 * minute_24fps;
			var l = iHH * hour_24fps + iMM * minute_24fps + iSS * second_24fps + iFF;

            // fix it now - dropframe
            this.mFrames = l;
            this.mFrames -= 2 * (l / minute_24fps);
            this.mFrames += 2 * (l / (10 * minute_24fps));
            return true;
        }
        else if (standard == 'EBU' || standard == '25') {
            this.mFpsn = 25;
            this.mFpsd = 1;
            this.bDropFrame = false;
            this.Frames = ((iHH * 60 + iMM) * 60 + iSS) * 25 + iFF;
            return true;
        }
        else if (standard == 'SMPTE' || standard == '30') {
            this.mFpsn = 30;
            this.mFpsd = 1;
            this.bDropFrame = false;
            var second_30fps = 30;
            var minute_30fps = 60 * second_30fps;
            var hour_30fps = 60 * minute_30fps;
            var l = iHH * hour_30fps + iMM * minute_30fps + iSS * second_30fps + iFF;
            this.mFrames = l;
            return true;
        }
        else if (standard == 'SMPTEDF' || standard == '29.97') {
            this.mFpsn = 30000;
            this.mFpsd = 1001;
            this.bDropFrame = true;
            var second_30fps = 30;
            var minute_30fps = 60 * second_30fps;
            var hour_30fps = 60 * minute_30fps;
            var l = iHH * hour_30fps + iMM * minute_30fps + iSS * second_30fps + iFF;

            // fix it now - dropframe
            this.mFrames = l;
            this.mFrames -= 2 * Math.floor(l / minute_30fps);
            this.mFrames += 2 * Math.floor(l / (10 * minute_30fps));
            return true;
        }
        return true;
    }

	getFrames()  {
        return this.mFrames;
	}

	addFrames(fr) {
        this.mFrames += fr;
        return this.mFrames;
    }

    static pad0(n) {
        return n.toString().padStart(2, "0");
    }

	// TimeCode2Str(strTimeCode) {        
    //     var frame = this.mFrames;

    //     var df = frame % Math.round(this.mFpsn / this.mFpsd); frame = frame / (mFpsn / mFpsd);
    //     var ds = frame % 60; frame = frame / 60;
    //     var dm = frame % 60; frame = frame / 60;
    //     var dh = frame % 60;

    //     var strTimeCode = `${this.pad0(dh)}:${this.pad0(dm)}:${this.pad0(ds)}:${this.pad0(df)}`;
    //     return strTimeCode;
    // }

    static TimeCode2Str(frame, mFpsn, mFpsd, bUseColon, bShowFrames) {
        //console.log("TimeCode2Str: " + frame);
        var colon = ':';
        if (mFpsd == 0)
        {
            return "00:00:00:00";
        }
        //debugger;
        if (mFpsd == 1001)
        {
            colon = ';';
            var tempFrames = frame;
	        var D = Math.floor(tempFrames / 17982);
            var M = tempFrames % 17982;
            var P = (M - 2);
            tempFrames += 18 * D + 2 * Math.sign(P) * Math.floor(Math.abs(P) / 1798);

            frame = tempFrames;
            //console.log("TimeCode2Str: adjusted " + frame);
        }

		// todo: adjust frame if dropframe
		var fps = Math.round(mFpsn / mFpsd);
        //console.log("TimeCode2Str: fps " + fps);
        
        var df = Math.round(frame % fps); frame = Math.floor(frame / fps);
        var ds = frame % 60; frame = Math.floor(frame / 60);
        var dm = frame % 60; frame = Math.floor(frame / 60);
        var dh = frame % 60;

        if (bUseColon) {
            if (bShowFrames){
                var strTimeCode = `${this.pad0(dh)}:${this.pad0(dm)}:${this.pad0(ds)}${colon}${this.pad0(df)}`;
                return strTimeCode;
            }
            else{
                var strTimeCode = `${this.pad0(dh)}:${this.pad0(dm)}:${this.pad0(ds)}`;
                return strTimeCode;
            }
        }
        else {
            if (bShowFrames){
                var strTimeCode = `${this.pad0(dh)}${this.pad0(dm)}${this.pad0(ds)}${this.pad0(df)}`;
                return strTimeCode;
            }
            else{
                var strTimeCode = `${this.pad0(dh)}${this.pad0(dm)}${this.pad0(ds)}`;
                return strTimeCode;
            }
        }
    }

    timeCode2Str(frame, bUseColon, bShowFrames) {
        //debugger;
        //console.log({ tc:this.mFrames, tm:frame });
        return CTimeCode.TimeCode2Str(this.mFrames + frame, this.mFpsn, this.mFpsd, bUseColon, bShowFrames)
    }

    static getNextRoundedFrame(startFrame, mFpsn, mFpsd, divider) {
        //console.log({ startFrame, divider });

        if (mFpsd == 1001) {
            var tempFrames = startFrame;
            var D = Math.floor(tempFrames / 17982);
            var M = tempFrames % 17982;
            var P = (M - 2);
            tempFrames += 18 * D + 2 * Math.sign(P) * Math.floor(Math.abs(P) / 1798);
            startFrame = tempFrames;
        }

        if ((startFrame % divider) == 0)
            return startFrame;

        var newFrame = Math.floor(startFrame / divider);
        newFrame++;
        newFrame = newFrame * divider;
        //console.log({ startFrame, divider, newFrame });
        return newFrame;
    }
}
