<template>
  <router-view />
</template>
<script>
import { watch } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios';
import router from './router';
import { nextTick } from 'vue';

export default {
  setup() {
    const store = useStore()

    watch(store.state, () => {
      store.state.theme === 'dark'
        ? document.body.classList.add('dark-theme')
        : document.body.classList.remove('dark-theme')
    })

    store.state.theme === 'dark'
      ? document.body.classList.add('dark-theme')
      : document.body.classList.remove('dark-theme')

    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  mounted() {
    console.log("APP MOUNTED 1");
    var qparams = {};

    console.log(window.location.hash);
    var hash = window.location.hash.slice(1);
    if (hash.length > 0)
    {
      var q = hash.indexOf('?');
      if (q != -1)
      {
        hash = hash.slice(q+1);
        qparams = hash.split('&').reduce(function (res, item) {
          var parts = item.split('=');
          res[parts[0]] = parts[1];
          return res;
        }, {});
        //console.log(qparams);
      }
    }
    var token = localStorage.getItem("token");
    console.log("APP MOUNTED 2");

    if (token !== null && token.length > 0) {
      this.tryResume(token, qparams);
      console.log("APP MOUNTED 3");
    }
    else
    {
      router.replace({ path: "/login" });      
    }
    console.log("APP MOUNTED 6");
  },
  methods: {
    async tryResume(token, query) {
      console.log("try-resume ");
      console.log(query);
      try {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        axios.defaults.withCredentials = true;
        const response = await axios({
          method: "post",
          url: this.purl + "/api/account/signin",
          data: { token: localStorage.getItem("token") },
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response != null)
        {          
          console.log("response.data = ", response.data);
          if (response.data.resume == true) {
            this.emitter.emit("user-resume", {
              username: localStorage.getItem("username"),
              token: localStorage.getItem("token")
            });
            console.log("APP MOUNTED 5", );
            await nextTick();
            router.replace({ path: window.location.hash, query: query });
          }
          else {
            this.emitter.emit("token-expired");
          }
        }
      }
      catch (error) {
        console.log(error);
        router.replace({ path: "/" });
      }
    }
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
