<template>
  <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    ">
    <CSidebarBrand style="margin-bottom: 8px;">
      <CIcon custom-class-name="sidebar-brand-full" :icon="centroidSwooshSmall" :height="75" />
      <CIcon custom-class-name="sidebar-brand-narrow" :icon="centroidRedSmall" :height="35" />
    </CSidebarBrand>
    <AppSidebarNav :nav="nav[userRole]" />
    <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')" />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { centroidSwooshSmall } from '@/assets/brand/centroid-swoosh-small'
import { centroidSmall } from '@/assets/brand/centroid-small'
import { centroidRedSmall } from '@/assets/brand/centroid-red-small'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import nav from '@/_nav.js'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    const userRole = store.state.userRole;
    return {
      nav,
      userRole,
      centroidSmall,
      centroidRedSmall,
      centroidSwooshSmall,
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
