"use strict";

import axios from 'axios';

export default {

    getOrderStatus: (status) => {
        if (status == 1) return "Edit";
        else if (status == 2) return "Wait for approval";
        else if (status == 3) return "Approved";
        else if (status == 4) return "Ordered";
        else if (status == 5) return "Processing";
        else if (status == 6) return "Delivered";
        else if (status == 7) return "Due";
        else if (status == 8) return "Paid";
        else return "???";
    },

    getInvoiceStatus: (status) => {

        if (status == 1) return "Due";
        else if (status == 2) return "Partial";
        else if (status == 3) return "Paid";
        else return "???";
    },

    fetchTakes: async (purl, sdayid, offset, itemsPerPage, params) => {
        try {
            var url = purl + `/api/trimmer/takes/list/${sdayid}`;
            if (offset !== undefined) url += `?offset=${offset}`;
            if (itemsPerPage !== undefined) url += `&limit=${itemsPerPage}`;
            if (params !== undefined) url += `&${params}`;
            var result = await axios.get(url);
            return result.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchOrderInfo: async (purl, orderId) => { 
        try {
            var url = purl + `/api/trimmer/orderinfo/${orderId}`;
            var result = await axios.get(url);
            return result.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchOrders: async (purl, sdayid, offset, itemsPerPage, params) => {
        try {
            var url = purl + `/api/trimmer/orders/list/${sdayid}`;
            if (offset !== undefined) url += `?offset=${offset}`;
            if (itemsPerPage !== undefined) url += `&limit=${itemsPerPage}`;
            if (params !== undefined) url += `&${params}`;
            var result = await axios.get(url);
            //var result = await axios.get(purl + `/api/trimmer/orders/list/${sdayid}`);
            return result.data;
        }
        catch (err)
        {
            console.log(err);
            return null;
        }
    },

    fetchDeliveredOrders: async (purl, offset, itemsPerPage, params) => { 
        try {
            var url = purl + `/api/accountant/orders/list`;
            if (offset !== undefined) url += `?offset=${offset}`;
            if (itemsPerPage !== undefined) url += `&limit=${itemsPerPage}`;
            if (params !== undefined) url += `&${params}`;
            var result = await axios.get(url);
            return result.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },
    fetchClientOrders: async (purl, offset, itemsPerPage, params) => {
        try {
            var url = purl + `/api/trimmer/clientorders/list`;
            if (offset !== undefined) url += `?offset=${offset}`;
            if (itemsPerPage !== undefined) url += `&limit=${itemsPerPage}`;
            if (params !== undefined) url += `&${params}`;
            var result = await axios.get(url);
            return result.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchAllOrders: async (purl, offset, itemsPerPage, params) => {
        try {
            var url = purl + `/api/trimmer/allorders/list`;
            if (offset !== undefined) url += `?offset=${offset}`;
            if (itemsPerPage !== undefined) url += `&limit=${itemsPerPage}`;
            if (params !== undefined) url += `&${params}`;
            var result = await axios.get(url);
            return result.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchActors: async(purl, sday, offset, itemsPerPage, params) => {
        try {
            var result = await axios.get(purl + `/api/trimmer/actors/list/${sday}?offset=${offset}&limit=${itemsPerPage}&${params}`);
            return result.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchTakesMediaMetadata: async (purl, takeid) => {
        try {
            var ret = await axios.get(purl + `/api/trimmer/take_media/list/${takeid}`);
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchActorsInTake: async (purl, takeid) => {
        try {
            var ret = await axios.get(purl + `/api/trimmer/takes/actors/${takeid}`);
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchActorsInCut: async (purl, cutid) => {
        try {
            var ret = await axios.get(purl + `/api/trimmer/cuts/actors/${cutid}`);
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchTakeMedia: async (purl, takeid) => {
        try {
            var ret = await axios.get(purl + `/api/trimmer/take_media/list/${takeid}`);
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    fetchPaymentsForInvoices: async (purl, id) => {
        try {
            var ret = await axios.get(purl + `/api/accountant/invoices/paymentslist/${id}`);
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    updateTakePrice: async (purl, takeId) => {
        try {
            var ret = await axios.get(purl + "/api/trimmer/takes/price/" + takeId);
            return ret.data;

        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    updateCut: async (purl, cid, cutRange) => {
        var ret = await axios({
            method: "post",
            url: purl + "/api/trimmer/takes/updatecut",
            data: {
                cutId: cid,
                range: cutRange
            },
            headers: { "Content-Type": "application/json" },
        });
        return ret.data;
    },

    addCut: async (purl, type, tid, oid, cutRange) => {
        var ret = await axios({
            method: "post",
            url: purl + "/api/trimmer/takes/addcut",
            data: {
                type: type,
                takeId: tid,
                orderId: oid,
                range: cutRange
            },
            headers: { "Content-Type": "application/json" },
        });
        return ret.data;
    },

    toggleActor: async (purl, method, cid, aid) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/cuts/" + method,
                data: {
                    cutId: cid,
                    actorId: aid,
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    moveCut: async (purl, cutId, orderId) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/cuts/move",
                data: {
                    cutId: cutId,
                    orderId: orderId,
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    copyCut: async (purl, cutId, orderId) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/cuts/copy",
                data: {
                    cutId: cutId,
                    orderId: orderId,
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    deleteCut: async (purl, cutId) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/cuts/delete",
                data: {
                    cutId: cutId
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    addPaymentToInvoice: async (purl, id, amount) => { 
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/accountant/invoices/addpayment",
                data: {
                    id: id,
                    amount: Math.floor(amount*100)
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    setInvoiceStatus: async (purl, id, status) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/accountant/invoices/setstatus",
                data: {
                    id: id,
                    status: status
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    setOrderStatus: async (purl, oid, status) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/orders/setstatus",
                data: {
                    orderId: oid,
                    status: status
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    updateOrderPrice: async (purl, oid) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/orders/updateprice",
                data: {
                    orderId: oid
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    calcOrderPrice: async (purl, oid, sid) => {
        //console.log("calcOrderPrice: ", { oid, sid });
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/orders/calcprice",
                data: {
                    orderId: oid,
                    sdayId: sid
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    exportXLS: async (purl, oid) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/orders/exportxls",
                data: {
                    orderId: oid
                },
                responseType: 'blob'
                //headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    exportTasks: async (purl, oid, tip) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/trimmer/orders/exporttasks",
                data: {
                    orderId: oid,
                    body: tip
                },
                responseType: 'blob'
                //headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    sendFile: async (purl, shooting_day_id, id, name, file, chunk_size, progress_cb) => {         
        var token = localStorage.getItem("token");

        const openw = async () => {
            try {
                var ret = await fetch(purl + "/api/admin/shootings/upload/openw", {
                    method: "post",
                    body: JSON.stringify({
                        id: id,
                        shooting_day_id: shooting_day_id,
                        name: name
                    }),
                    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
                });
                return await ret.json();
            }
            catch (err) {
                console.log(err);
                return null;
            }
        }

        const sendPart = async (ftoken, blob) => {
            try {
                var form = new FormData();
                form.append('file', blob, name);
                form.append('token', ftoken);
                // form.append('shooting_day_id', shooting_day_id);
                // form.append('name', name);
                var ret = await fetch(purl + "/api/admin/shootings/upload/write", {
                    method: "post",
                    body: form,
                    headers: {
                        //"Content-Type": "multipart/form-data",
                        "Authorization" : "Bearer " + token,
                        "X-FToken" : ftoken,
                        "X-Name" : name
                    },
                });
                return await ret.json();
            }
            catch (err) {
                console.log(err);
                return null;
            }
        }

        const sendPartNew = async (ftoken, blob) => {
            try {
                var ret = await fetch(purl + "/api/admin/shootings/upload/write", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/octet-stream",
                        "Authorization": "Bearer " + token,
                        "X-FToken": ftoken,
                        "X-Name": name
                    },
                    body: blob
                });
                return await ret.json();
            }
            catch (err) {
                console.log(err);
                return null;
            }
        }

        const close = async (ftoken) => {
            try {
                var ret = await fetch(purl + "/api/admin/shootings/upload/close", {
                    method: "post",
                    body: JSON.stringify({
                        token: ftoken
                    }),
                    headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
                });
                return await ret.json();
            }
            catch (err) {
                console.log(err);
                return null;
            }
        }

        var ret = await openw();
        if (ret == null)
            return false;
        
        //console.log(ret);

        if (ret.cmd == "opened") {
            var currentPos = ret.currentSize;
            var ftoken = ret.token;
            while (currentPos < file.size) {
                var next_slice = currentPos + chunk_size;
                var blob = file.slice(currentPos, next_slice);

                var ret2 = await sendPartNew(ftoken, blob);
                if (ret2 == null)
                    return false;

                progress_cb(currentPos, file.size);
                currentPos += blob.size;
            }

            await close(ftoken, id);

            return true;
        }
        else return false;
    },

    deleteShootingDay: async(purl, shooting_day_id) => {
        try {
            var ret = await axios({
                method: "post",
                url: purl + "/api/admin/shootings/delete",
                data: {
                    sid: shooting_day_id
                },
                headers: { "Content-Type": "application/json" },
            });
            return ret.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }    
    },

    fetchInvoices: async (purl, offset, itemsPerPage, params) => {
        try {
            var url = purl + `/api/accountant/invoices/list`;
            if (offset !== undefined) url += `?offset=${offset}`;
            if (itemsPerPage !== undefined) url += `&limit=${itemsPerPage}`;
            if (params !== undefined) url += `&${params}`;
            var result = await axios.get(url);
            return result.data;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    },

    createInvoice: async (purl, form) => {
        var ret = await axios({
            method: "post",
            url: purl + "/api/accountant/invoices/create",
            data: form,
            headers: { "Content-Type": "application/json" },
        });
        return ret.data;
    }

}   

/*
        const openwOld = async (purl, shooting_day_id, name) => {
            try {
                var ret = await axios({
                    method: "post",
                    url: purl + "/api/admin/shootings/upload/openw",
                    data: {
                        shooting_day_id: shooting_day_id,
                        name: name
                    },
                    headers: { "Content-Type": "application/json" },
                });
                return ret.data;
            }
            catch (err) {
                console.log(err);
                return null;
            }
        }

        const sendPartOld = async (purl, shooting_day_id, name, blob) => {
            try {
                var form = new FormData();
                form.append('file', blob, name);
                form.append('shooting_day_id', shooting_day_id);
                form.append('name', name);
                var ret = await axios({
                    method: "post",
                    url: purl + "/api/admin/shootings/upload/write",
                    data: form,
                    headers: { "Content-Type": "multipart/form-data" },
                });
                return ret.data;
            }
            catch (err) {
                console.log(err);
                return null;
            }
        }
 
*/