import axios from 'axios';

function onProgress(ev) {
    console.log("progress...", ev);    
}

function onLoad() {
    console.log("onload");
    if (this.src) {
        console.log("revokeObjectURL");
        URL.revokeObjectURL(this.src);
        this.removeEventListener("load", onLoad);
        //this.removeEventListener("progress", onProgress);
    }
}

export default {
    img: function (img, url) {
        //console.log(url);
        img.addEventListener("load", onLoad);
        axios.request({
            url: url,
            method: "get",
            responseType: "blob"
        }).then(res => {
            var blobUrl = URL.createObjectURL(res.data);
            img.src = blobUrl;
        }).catch(err => console.log(err));
    },
    video: function (video, url, cb, pcb) {        
        video.addEventListener("load", onLoad);
        // video.addEventListener("progress", onProgress);

        axios.request({
            url: url,
            method: "get",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => { 
                //console.log("Progress: ", progressEvent);
                if (pcb != null)
                    pcb(progressEvent.progress * 100);
            }
        }).then(async (res) => {
            var ab = await res.data.arrayBuffer();
            var blobUrl = URL.createObjectURL(new Blob([ab], { type: "video/mp4" }));
            video.src = blobUrl;
            cb(true);
        }).catch(err => {
            console.log(err);
            console.log("ERROR");
            cb(false);
        });
    },
}




/*
// this works in Safari and Chrome
var ab = await res.data.arrayBuffer();
var blobUrl = URL.createObjectURL(new Blob([ab], { type: "video/mp4" }));

// this works in Chrome but not in Safari
var blobUrl = URL.createObjectURL(new Blob([await res.data.arrayBuffer()], { type: "video/mp4" }));
*/




