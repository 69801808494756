<template>
  <div>
    <video autoplay muted loop id="myVideo">
      <source src="back.mp4" type="video/mp4">
    </video>
    <div class="min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow class="justify-content-center">
          <CCol :md="8">
            <CCardGroup>
              <CCard class="p-4">
                <CCardBody>
                  <CForm @submit.prevent="onSubmit">
                    <h1>Login</h1>
                    <p class="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup class="mb-3">
                      <CInputGroupText>
                        <CIcon icon="cil-user" />
                      </CInputGroupText>
                      <CFormInput placeholder="Username" autocomplete="username" v-model="form.username" />
                    </CInputGroup>
                    <CInputGroup class="mb-4">
                      <CInputGroupText>
                        <CIcon icon="cil-lock-locked" />
                      </CInputGroupText>
                      <CFormInput type="password" placeholder="Password" autocomplete="current-password"
                        v-model="form.password" />
                    </CInputGroup>
                    <CRow>
                      <CCol :xs="12">
                        <CButton type="submit" color="primary" class="px-4"> Login </CButton>
                      </CCol>
                      <!-- <CCol :xs="6" class="text-right">
                        <CButton color="link" class="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> -->
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>

            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'Login',
  data() {
    return {
      form: {
        username:'',
        password:''
      }
    }
  },
  methods: {
    onSubmit(evt)
    {
      evt.preventDefault();

      const postData = {
        username: this.form.username,
        password: this.form.password
      };

      axios({
          method : "post",
          url : this.purl + "/api/account/login",
          data : postData
      }).then((res) =>{
        console.log(res);
        this.emitter.emit("user-login", {username:res.data.username, token:res.data.accessToken});
      })
    } 
  }
}
</script>

<style>
#myVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
</style>