import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import Login from '@/views/Login.vue';
import Trimmer from '@/views/common/Trimmer.vue';



//superadmin pages
//const SuperAdminHome = () => import('@/views/superadmin/Home.vue');
//const AdminHome = () => import('@/views/admin/AdminHome.vue');
const Main = () => import('@/views/Main.vue');

const SAProjectsHome = () => import('@/views/superadmin/SAProjectsHome.vue');
const SAAdminProjects = () => import('@/views/superadmin/SAAdminProjects.vue');
const SAShootings = () => import('@/views/superadmin/SAShootings.vue');
const SAOneShootingDay = () => import('@/views/superadmin/SAOneShootingDay.vue');


const AProjectsHome = () => import('@/views/admin/AProjectsHome.vue');
const AAdminProjects = () => import('@/views/admin/AAdminProjects.vue');
const AShootings = () => import('@/views/admin/AShootings.vue');
const AOneShootingDay = () => import('@/views/admin/AOneShootingDay.vue');


const CAProjectsHome = () => import('@/views/clientadmin/CAProjectsHome.vue');
const CAAdminProjects = () => import('@/views/clientadmin/CAAdminProjects.vue');
const CAShootings = () => import('@/views/clientadmin/CAShootings.vue');
const CAOneShootingDay = () => import('@/views/clientadmin/CAOneShootingDay.vue');


const CEProjectsHome = () => import('@/views/clienteditor/CEProjectsHome.vue');
const CEProjects = () => import('@/views/clienteditor/CEProjects.vue');
const CEShootings = () => import('@/views/clienteditor/CEShootings.vue');
const CEOneShootingDay = () => import('@/views/clienteditor/CEOneShootingDay.vue');


const CPProjectsHome = () => import('@/views/clientproducer/CPProjectsHome.vue');
const CPProjects = () => import('@/views/clientproducer/CPProjects.vue');
const CPShootings = () => import('@/views/clientproducer/CPShootings.vue');
const CPOneShootingDay = () => import('@/views/clientproducer/CPOneShootingDay.vue');


const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/trimmer',
    name: 'Trimmer',
    component: Trimmer,
  },
]

function configRoutes() {
  return [
    {
      path: '/',
      name: 'Main',
      component: Main,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/trimmer',
      name: 'Trimmer',
      component: Trimmer,
    },
  ]
};

const SuperAdminRoutes =
  [
    {
      path: '/suadmin',
      name: 'Home',
      component: DefaultLayout,
      redirect: '/suadmin/home',
      children: [
        {
          path: '/suadmin/home',
          name: 'Home',
          component: () => import('@/views/superadmin/Home.vue'),
        },
        {
          path: '/suadmin/users',
          name: 'Users',
          component: () => import('@/views/superadmin/Users.vue'),
        },
        {
          path: '/suadmin/clients',
          name: 'Clients',
          component: () => import('@/views/superadmin/Clients.vue'),
        },
        {
          path: '/suadmin/projects',
          name: 'Projects',
          redirect: '/suadmin/projects/home',
          component: SAProjectsHome,
          //{render() { return h(resolveComponent('router-view')) }},
          children: [
            {
              path: 'home',
              name: 'AllProjects',
              component: SAAdminProjects//() => import('@/views/superadmin/Projects.vue'),
            },
            {
              path: 'shootings',
              name: 'Shootings',
              component: SAShootings//() => import('@/views/superadmin/Shootings.vue'),
            },
            {
              path: 'oneshootingday',
              name: 'OneShootingDay',
              redirect: '/suadmin/projects/oneshootingday/takes',
              component: SAOneShootingDay,//() => import('@/views/superadmin/Shootings.vue'),
              children: [
                {
                  path: 'takes',
                  name: 'Takes',
                  component: SAShootings//() => import('@/views/superadmin/Shootings.vue'),
                },
                {
                  path: 'actors',
                  name: 'Actors',
                  component: SAShootings//() => import('@/views/superadmin/Shootings.vue'),
                },
                {
                  path: 'orders',
                  name: 'Orders',
                  component: SAShootings//() => import('@/views/superadmin/Shootings.vue'),
                },
              ]
            }
          ]
        },
      ]
    }
  ];

// mora da se popuni, nije zavrseno 
const AdminRoutes = [
  {
    path: '/admin',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/admin/home',
    children: [
      {
        path: '/admin/home',
        name: 'Home',
        component: () => import('@/views/admin/Home.vue'),
      },
      {
        path: '/admin/users',
        name: 'Users',
        component: () => import('@/views/admin/Users.vue'),
      },
      {
        path: '/admin/clients',
        name: 'Clients',
        component: () => import('@/views/superadmin/Clients.vue'),
      },
      {
        path: '/admin/projects',
        name: 'Projects',
        redirect: '/admin/projects/home',
        component: AProjectsHome,
        //{render() { return h(resolveComponent('router-view')) }},
        children: [
          {
            path: 'home',
            name: 'AllProjects',
            component: AAdminProjects//() => import('@/views/superadmin/Projects.vue'),
          },
          {
            path: 'shootings',
            name: 'Shootings',
            component: AShootings//() => import('@/views/superadmin/Shootings.vue'),
          },
          {
            path: 'oneshootingday',
            name: 'OneShootingDay',
            redirect: '/admin/projects/oneshootingday/takes',
            component: AOneShootingDay,//() => import('@/views/superadmin/Shootings.vue'),
            children: [
              {
                path: 'takes',
                name: 'Takes',
                component: AShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'actors',
                name: 'Actors',
                component: AShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'orders',
                name: 'Orders',
                component: AShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
            ]
          }
        ]
      },
      {
        path: '/admin/orders',
        name: 'Orders',
        component: () => import('@/views/admin/AAllOrders.vue'),
      },
      {
        path: '/admin/reports',
        name: 'Reports',
        component: () => import('@/views/admin/Reports.vue'),
      },
    ]
  }
];

const TechnicianRoutes = [
  {
    path: '/technician',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/technician/home',
    children: [
      {
        path: '/technician/home',
        name: 'Home',
        component: () => import('@/views/technician/Home.vue'),
      },
      {
        path: '/technician/clients',
        name: 'Users',
        component: () => import('@/views/technician/Clients.vue'),
      },
      {
        path: '/technician/projects',
        name: 'Projects',
        component: () => import('@/views/technician/Projects.vue'),
      },
      {
        path: '/technician/orders',
        name: 'Orders',
        component: () => import('@/views/technician/Orders.vue'),
      },

    ]
  }
];

const AccountantRoutes = [
  {
    path: '/accountant',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/accountant/home',
    children: [
      {
        path: '/accountant/home',
        name: 'Home',
        component: () => import('@/views/accountant/Home.vue'),
      },
      {
        path: '/accountant/orders',
        name: 'Orders',
        component: () => import('@/views/accountant/AAllOrders.vue'),
      },
      {
        path: '/accountant/invoices',
        name: 'Invoices',
        component: () => import('@/views/accountant/AInvoices.vue'),
      },
    ]
  }
];

const ClientAdminRoutes = [
  {
    path: '/clientadmin',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/clientadmin/home',
    children: [
      {
        path: '/clientadmin/home',
        name: 'Home',
        component: () => import('@/views/clientadmin/Home.vue'),
      },
      {
        path: '/clientadmin/users',
        name: 'Users',
        component: () => import('@/views/clientadmin/Users.vue'),
      },
      {
        path: '/clientadmin/projects',
        name: 'Projects',
        redirect: '/clientadmin/projects/home',
        component: CAProjectsHome,
        //{render() { return h(resolveComponent('router-view')) }},
        children: [
          {
            path: 'home',
            name: 'AllProjects',
            component: CAAdminProjects//() => import('@/views/superadmin/Projects.vue'),
          },
          {
            path: 'shootings',
            name: 'Shootings',
            component: CAShootings//() => import('@/views/superadmin/Shootings.vue'),
          },
          {
            path: 'oneshootingday',
            name: 'OneShootingDay',
            redirect: '/clientadmin/projects/oneshootingday/takes',
            component: CAOneShootingDay,//() => import('@/views/superadmin/Shootings.vue'),
            children: [
              {
                path: 'takes',
                name: 'Takes',
                component: CAShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'actors',
                name: 'Actors',
                component: CAShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'orders',
                name: 'Orders',
                component: CAShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
            ]
          }
        ]
      },
      {
        path: '/clientadmin/orders',
        name: 'Orders',
        component: () => import('@/views/clientadmin/CAAllOrders.vue'),
      },
      {
        path: '/clientadmin/reports',
        name: 'Reports',
        component: () => import('@/views/clientadmin/Reports.vue'),
      },
    ]
  }
];

const ClientProducerRoutes = [
  {
    path: '/clproducer',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/clproducer/home',
    children: [
      {
        path: '/clproducer/home',
        name: 'Home',
        component: () => import('@/views/clientproducer/Home.vue'),
      },
      {
        path: '/clproducer/projects',
        name: 'Projects',
        redirect: '/clproducer/projects/home',
        component: CPProjectsHome,
        //{render() { return h(resolveComponent('router-view')) }},
        children: [
          {
            path: 'home',
            name: 'AllProjects',
            component: CPProjects//() => import('@/views/superadmin/Projects.vue'),
          },
          {
            path: 'shootings',
            name: 'Shootings',
            component: CPShootings//() => import('@/views/superadmin/Shootings.vue'),
          },
          {
            path: 'oneshootingday',
            name: 'OneShootingDay',
            redirect: '/clproducer/projects/oneshootingday/takes',
            component: CPOneShootingDay,//() => import('@/views/superadmin/Shootings.vue'),
            children: [
              {
                path: 'takes',
                name: 'Takes',
                component: CPShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'actors',
                name: 'Actors',
                component: CPShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'orders',
                name: 'Orders',
                component: CPShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
            ]
          }
        ]
      },
      {
        path: '/clproducer/orders',
        name: 'Orders',
        component: () => import('@/views/clientproducer/CPAllOrders.vue'),
      },
      {
        path: '/clproducer/reports',
        name: 'Reports',
        component: () => import('@/views/clientproducer/Reports.vue'),
      },
    ]
  }
];

const ClientEditorRoutes = [
  {
    path: '/cleditor',
    name: 'Projects',
    component: DefaultLayout,
    redirect: '/cleditor/projects',
    children: [
      {
        path: '/cleditor/projects',
        name: 'Projects',
        redirect: '/cleditor/projects/home',
        component: CEProjectsHome,
        //{render() { return h(resolveComponent('router-view')) }},
        children: [
          {
            path: 'home',
            name: 'AllProjects',
            component: CEProjects//() => import('@/views/superadmin/Projects.vue'),
          },
          {
            path: 'shootings',
            name: 'Shootings',
            component: CEShootings//() => import('@/views/superadmin/Shootings.vue'),
          },
          {
            path: 'oneshootingday',
            name: 'OneShootingDay',
            redirect: '/cleditor/projects/oneshootingday/takes',
            component: CEOneShootingDay,//() => import('@/views/superadmin/Shootings.vue'),
            children: [
              {
                path: 'takes',
                name: 'Takes',
                component: CEShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'actors',
                name: 'Actors',
                component: CEShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
              {
                path: 'orders',
                name: 'Orders',
                component: CEShootings//() => import('@/views/superadmin/Shootings.vue'),
              },
            ]
          }
        ]
      },
      {
        path: '/cleditor/orders',
        name: 'Orders',
        component: () => import('@/views/clienteditor/Orders.vue'),
      },
    ]
  }
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  //history: createWebHistory(process.env.BASE_URL),
  routes: configRoutes(),
  superadmin_routes: SuperAdminRoutes,
  admin_routes: AdminRoutes,
  clientadmin_routes: ClientAdminRoutes,
  accountatnt_routes: AccountantRoutes,
  technician_routes: TechnicianRoutes,
  clientproducer_routes: ClientProducerRoutes,
  clienteditor_routes: ClientEditorRoutes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router




  //   path: '/',
  //   name: 'Home',
  //   component: DefaultLayout,
  //   redirect: '/dashboard',
  //   children: [
  //     {
  //       path: '/dashboard',
  //       name: 'Dashboard',
  //       // route level code-splitting
  //       // this generates a separate chunk (about.[hash].js) for this route
  //       // which is lazy-loaded when the route is visited.
  //       component: () =>
  //         import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
  //     },
  //     {
  //       path: '/theme',
  //       name: 'Theme',
  //       redirect: '/theme/typography',
  //     },
  //     {
  //       path: '/theme/colors',
  //       name: 'Colors',
  //       component: () => import('@/views/theme/Colors.vue'),
  //     },
  //     {
  //       path: '/theme/typography',
  //       name: 'Typography',
  //       component: () => import('@/views/theme/Typography.vue'),
  //     },
  //     {
  //       path: '/base',
  //       name: 'Base',
  //       component: {
  //         render() {
  //           return h(resolveComponent('router-view'))
  //         },
  //       },
  //       redirect: '/base/breadcrumbs',
  //       children: [
  //         {
  //           path: '/base/accordion',
  //           name: 'Accordion',
  //           component: () => import('@/views/base/Accordion.vue'),
  //         },
  //         {
  //           path: '/base/breadcrumbs',
  //           name: 'Breadcrumbs',
  //           component: () => import('@/views/base/Breadcrumbs.vue'),
  //         },
  //         {
  //           path: '/base/cards',
  //           name: 'Cards',
  //           component: () => import('@/views/base/Cards.vue'),
  //         },
  //         {
  //           path: '/base/carousels',
  //           name: 'Carousels',
  //           component: () => import('@/views/base/Carousels.vue'),
  //         },
  //         {
  //           path: '/base/collapses',
  //           name: 'Collapses',
  //           component: () => import('@/views/base/Collapses.vue'),
  //         },
  //         {
  //           path: '/base/list-groups',
  //           name: 'List Groups',
  //           component: () => import('@/views/base/ListGroups.vue'),
  //         },
  //         {
  //           path: '/base/navs',
  //           name: 'Navs',
  //           component: () => import('@/views/base/Navs.vue'),
  //         },
  //         {
  //           path: '/base/paginations',
  //           name: 'Paginations',
  //           component: () => import('@/views/base/Paginations.vue'),
  //         },
  //         {
  //           path: '/base/placeholders',
  //           name: 'Placeholders',
  //           component: () => import('@/views/base/Placeholders.vue'),
  //         },
  //         {
  //           path: '/base/popovers',
  //           name: 'Popovers',
  //           component: () => import('@/views/base/Popovers.vue'),
  //         },
  //         {
  //           path: '/base/progress',
  //           name: 'Progress',
  //           component: () => import('@/views/base/Progress.vue'),
  //         },
  //         {
  //           path: '/base/spinners',
  //           name: 'Spinners',
  //           component: () => import('@/views/base/Spinners.vue'),
  //         },
  //         {
  //           path: '/base/tables',
  //           name: 'Tables',
  //           component: () => import('@/views/base/Tables.vue'),
  //         },
  //         {
  //           path: '/base/tooltips',
  //           name: 'Tooltips',
  //           component: () => import('@/views/base/Tooltips.vue'),
  //         },
  //       ],
  //     },
  //     {
  //       path: '/buttons',
  //       name: 'Buttons',
  //       component: {
  //         render() {
  //           return h(resolveComponent('router-view'))
  //         },
  //       },
  //       redirect: '/buttons/standard-buttons',
  //       children: [
  //         {
  //           path: '/buttons/standard-buttons',
  //           name: 'Buttons',
  //           component: () => import('@/views/buttons/Buttons.vue'),
  //         },
  //         {
  //           path: '/buttons/dropdowns',
  //           name: 'Dropdowns',
  //           component: () => import('@/views/buttons/Dropdowns.vue'),
  //         },
  //         {
  //           path: '/buttons/button-groups',
  //           name: 'Button Groups',
  //           component: () => import('@/views/buttons/ButtonGroups.vue'),
  //         },
  //       ],
  //     },
  //     {
  //       path: '/forms',
  //       name: 'Forms',
  //       component: {
  //         render() {
  //           return h(resolveComponent('router-view'))
  //         },
  //       },
  //       redirect: '/forms/form-control',
  //       children: [
  //         {
  //           path: '/forms/form-control',
  //           name: 'Form Control',
  //           component: () => import('@/views/forms/FormControl.vue'),
  //         },
  //         {
  //           path: '/forms/select',
  //           name: 'Select',
  //           component: () => import('@/views/forms/Select.vue'),
  //         },
  //         {
  //           path: '/forms/multi-select',
  //           name: 'Multi Select',
  //           component: () => import('@/views/forms/MultiSelect.vue'),
  //         },
  //         {
  //           path: '/forms/checks-radios',
  //           name: 'Checks & Radios',
  //           component: () => import('@/views/forms/ChecksRadios.vue'),
  //         },
  //         {
  //           path: '/forms/range',
  //           name: 'Range',
  //           component: () => import('@/views/forms/Range.vue'),
  //         },
  //         {
  //           path: '/forms/input-group',
  //           name: 'Input Group',
  //           component: () => import('@/views/forms/InputGroup.vue'),
  //         },
  //         {
  //           path: '/forms/floating-labels',
  //           name: 'Floating Labels',
  //           component: () => import('@/views/forms/FloatingLabels.vue'),
  //         },
  //         {
  //           path: '/forms/date-picker',
  //           name: 'Date Picker',
  //           component: () => import('@/views/forms/DatePicker.vue'),
  //         },
  //         {
  //           path: '/forms/date-range-picker',
  //           name: 'Date Range Picker',
  //           component: () => import('@/views/forms/DateRangePicker.vue'),
  //         },
  //         {
  //           path: '/forms/time-picker',
  //           name: 'Time Picker',
  //           component: () => import('@/views/forms/TimePicker.vue'),
  //         },
  //         {
  //           path: '/forms/layout',
  //           name: 'Layout',
  //           component: () => import('@/views/forms/Layout.vue'),
  //         },
  //         {
  //           path: '/forms/validation',
  //           name: 'Validation',
  //           component: () => import('@/views/forms/Validation.vue'),
  //         },
  //       ],
  //     },
  //     {
  //       path: '/charts',
  //       name: 'Charts',
  //       component: () => import('@/views/charts/Charts.vue'),
  //     },
  //     {
  //       path: '/icons',
  //       name: 'Icons',
  //       component: {
  //         render() {
  //           return h(resolveComponent('router-view'))
  //         },
  //       },
  //       redirect: '/icons/coreui-icons',
  //       children: [
  //         {
  //           path: '/icons/coreui-icons',
  //           name: 'CoreUI Icons',
  //           component: () => import('@/views/icons/CoreUIIcons.vue'),
  //         },
  //         {
  //           path: '/icons/brands',
  //           name: 'Brands',
  //           component: () => import('@/views/icons/Brands.vue'),
  //         },
  //         {
  //           path: '/icons/flags',
  //           name: 'Flags',
  //           component: () => import('@/views/icons/Flags.vue'),
  //         },
  //       ],
  //     },
  //     {
  //       path: '/notifications',
  //       name: 'Notifications',
  //       component: {
  //         render() {
  //           return h(resolveComponent('router-view'))
  //         },
  //       },
  //       redirect: '/notifications/alerts',
  //       children: [
  //         {
  //           path: '/notifications/alerts',
  //           name: 'Alerts',
  //           component: () => import('@/views/notifications/Alerts.vue'),
  //         },
  //         {
  //           path: '/notifications/badges',
  //           name: 'Badges',
  //           component: () => import('@/views/notifications/Badges.vue'),
  //         },
  //         {
  //           path: '/notifications/modals',
  //           name: 'Modals',
  //           component: () => import('@/views/notifications/Modals.vue'),
  //         },
  //       ],
  //     },
  //     {
  //       path: '/widgets',
  //       name: 'Widgets',
  //       component: () => import('@/views/widgets/Widgets.vue'),
  //     },
  //     {
  //       path: '/smart-table',
  //       name: 'Smart Table',
  //       component: () => import('@/views/smart-table/SmartTable.vue'),
  //     },
  //     {
  //       path: '/calendar',
  //       name: 'Calendar',
  //       component: () => import('@/views/plugins/Calendar.vue'),
  //     },
  //     {
  //       path: 'apps',
  //       name: 'Apps',
  //       redirect: '/apps/invoicing/invoice',
  //       component: {
  //         render() {
  //           return h(resolveComponent('router-view'))
  //         },
  //       },
  //       children: [
  //         {
  //           path: 'invoicing',
  //           redirect: '/apps/invoicing/invoice',
  //           name: 'Invoicing',
  //           component: {
  //             render() {
  //               return h(resolveComponent('router-view'))
  //             },
  //           },
  //           children: [
  //             {
  //               path: 'invoice',
  //               name: 'Invoice',
  //               component: () => import('@/views/apps/invoicing/Invoice.vue'),
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   path: '/pages',
  //   redirect: '/pages/404',
  //   name: 'Pages',
  //   component: {
  //     render() {
  //       return h(resolveComponent('router-view'))
  //     },
  //   },
  //   children: [
  //     {
  //       path: '404',
  //       name: 'Page404',
  //       component: () => import('@/views/pages/Page404'),
  //     },
  //     {
  //       path: '500',
  //       name: 'Page500',
  //       component: () => import('@/views/pages/Page500'),
  //     },
  //     {
  //       path: 'login',
  //       name: 'Login',
  //       component: () => import('@/views/pages/Login'),
  //     },
  //     {
  //       path: 'register',
  //       name: 'Register',
  //       component: () => import('@/views/pages/Register'),
  //     },
  //   ],
  // },
  // {
  //   path: '/apps/email',
  //   redirect: '/apps/email/inbox',
  //   name: 'Email',
  //   component: () => import('@/views/apps/email/EmailApp.vue'),
  //   children: [
  //     {
  //       path: 'compose',
  //       name: 'Compose',
  //       component: () => import('@/views/apps/email/Compose.vue'),
  //     },
  //     {
  //       path: 'inbox',
  //       name: 'Inbox',
  //       component: () => import('@/views/apps/email/Inbox.vue'),
  //     },
  //     {
  //       path: 'message',
  //       name: 'Message',
  //       component: () => import('@/views/apps/email/Message.vue'),
  //     },
  //   ],