import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import mitt from 'mitt';
import axios from 'axios';

const emitter = mitt();

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

// context menu
import ContextMenu from '@imengyu/vue3-context-menu'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'

const app = createApp(App);
app.use(ContextMenu);
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.component('DocsCallout', DocsCallout);
app.component('DocsExample', DocsExample);

if (process.env.NODE_ENV == "development")
  app.config.globalProperties.purl = "http://localhost:8010";
else
  app.config.globalProperties.purl = ""; //"https://bbb.sidzi.com:8443";

app.config.globalProperties.emitter = emitter;

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

emitter.on("token-expired", L => {
  store.commit('setToken', "");
  localStorage.setItem("token", "");
  localStorage.setItem("username", "");
  localStorage.clear();
  store.commit('setIsSuperAdmin', false);
  store.commit('setIsAdmin', false);
  store.commit('setIsClientAdmin', false);
  store.commit('setIsAccountant', false);
  store.commit('setIsTechnician', false);
  store.commit('setIsClientProducer', false);
  store.commit('setIsClientEditor', false);
  router.replace('/');
});

emitter.on("user-login", async (L) => {
  console.log("user login from main.js");
  //console.log(L);

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + L.token;
  axios.defaults.withCredentials = true;
  store.commit('setToken', L.token);
  localStorage.setItem("token", L.token);
  localStorage.setItem("username", L.username);

  var jwtPayload = parseJwt(L.token);
  console.log(jwtPayload);

  const isSuperAdmin = jwtPayload.roles.find(element => element == 'Superadmin');
  const isAdmin = jwtPayload.roles.find(element => element == 'Admin');
  const isClientAdmin = jwtPayload.roles.find(element => element == 'Client-admin');
  const isAccountant = jwtPayload.roles.find(element => element == 'Accountant');
  const isTechnician = jwtPayload.roles.find(element => element == 'Technician');
  const isClientProducer = jwtPayload.roles.find(element => element == 'Client-producer');
  const isClientEditor = jwtPayload.roles.find(element => element == 'Client-editor');

  if (isSuperAdmin) {
    store.commit('setUserRole', "SuperAdmin");
    store.commit('setIsSuperAdmin', true);
    router.options.superadmin_routes.forEach(r => router.addRoute(r));
    await router.replace('/suadmin');
    //router.replace('/');
  }
  else if (isAdmin) {
    store.commit('setUserRole', "Admin");
    store.commit('setIsAdmin', true);
    router.options.admin_routes.forEach(r => router.addRoute(r));
    await router.replace('/admin/home');
    //router.replace('/');
  }
  else if (isClientAdmin) {
    store.commit('setUserRole', "ClientAdmin");
    store.commit('setIsClientAdmin', true);
    router.options.clientadmin_routes.forEach(r => router.addRoute(r));
    await router.replace('/clientadmin/home');
    //router.replace('/');
  }
  else if (isAccountant) {
    console.log("isAccountant");
    store.commit('setUserRole', "Accountant");
    store.commit('setIsAccountant', true);
    router.options.accountatnt_routes.forEach(r => router.addRoute(r));
    await router.replace('/accountant/home');
    //router.replace('/');
  }
  else if (isTechnician) {
    store.commit('setUserRole', "Technician");
    store.commit('setIsTechnician', true);
    router.options.technician_routes.forEach(r => router.addRoute(r));
    await router.replace('/technician/home');
    //router.replace('/');
  }
  else if (isClientProducer) {
    store.commit('setUserRole', "ClientProducer");
    store.commit('setIsClientProducer', true);
    router.options.clientproducer_routes.forEach(r => router.addRoute(r));
    await router.replace('/clproducer/home');
    //router.replace('/');
  }
  else if (isClientEditor) {
    store.commit('setUserRole', "ClientEditor");
    store.commit('setIsClientEditor', true);
    router.options.clienteditor_routes.forEach(r => router.addRoute(r));
    await router.replace('/cleditor/projects');
    //router.replace('/');
  }
  else {
    store.commit('setUserRole', "User");
    store.commit('setIsClientAdmin', false);
    store.commit('setIsAdmin', false);
    store.commit('setIsSuperAdmin', false);
    store.commit('setIsAccountant', false);
    store.commit('setIsTechnician', false);
    store.commit('setIsClientProducer', false);
    store.commit('setIsClientEditor', false);

    await router.replace('/');
  }
  //router.replace('/');
});

emitter.on("user-resume", async (L) => {
  console.log("user-resume " + L.token);
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + L.token;
  axios.defaults.withCredentials = true;
  store.commit('setToken', L.token);
  localStorage.setItem("token", L.token);
  localStorage.setItem("username", L.username);

  var jwtPayload = parseJwt(L.token);
  console.log(jwtPayload);

  const isSuperAdmin = jwtPayload.roles.find(element => element == 'Superadmin');
  const isAdmin = jwtPayload.roles.find(element => element == 'Admin');
  const isClientAdmin = jwtPayload.roles.find(element => element == 'Client-admin');
  const isAccountant = jwtPayload.roles.find(element => element == 'Accountant');
  const isTechnician = jwtPayload.roles.find(element => element == 'Technician');
  const isClientProducer = jwtPayload.roles.find(element => element == 'Client-producer');
  const isClientEditor = jwtPayload.roles.find(element => element == 'Client-editor');

  if (isSuperAdmin) {
    store.commit('setUserRole', "SuperAdmin");
    store.commit('setIsSuperAdmin', true);
    router.options.superadmin_routes.forEach(r => router.addRoute(r));
    //await router.replace('/suadmin');
    //router.replace('/');
  }
  else if (isAdmin) {
    store.commit('setUserRole', "Admin");
    store.commit('setIsAdmin', true);
    router.options.admin_routes.forEach(r => router.addRoute(r));
    //await router.replace('/admin/home');
    //router.replace('/');
  }
  else if (isClientAdmin) {
    store.commit('setUserRole', "ClientAdmin");
    store.commit('setIsClientAdmin', true);
    router.options.clientadmin_routes.forEach(r => router.addRoute(r));
    //await router.replace('/clientadmin/home');
    //router.replace('/');
  }
  else if (isAccountant) {
    console.log("isAccountant-2");
    store.commit('setUserRole', "Accountant");
    store.commit('setIsAccountant', true);
    router.options.accountatnt_routes.forEach(r => router.addRoute(r));
    //await router.replace('/accountant/home');
    //router.replace('/');
  }
  else if (isTechnician) {
    store.commit('setUserRole', "Technician");
    store.commit('setIsTechnician', true);
    router.options.technician_routes.forEach(r => router.addRoute(r));
    //await router.replace('/technician/home');
    //router.replace('/');
  }
  else if (isClientProducer) {
    store.commit('setUserRole', "ClientProducer");
    store.commit('setIsClientProducer', true);
    router.options.clientproducer_routes.forEach(r => router.addRoute(r));
    //await router.replace('/clproducer/home');
    //router.replace('/');
  }
  else if (isClientEditor) {
    store.commit('setUserRole', "ClientEditor");
    store.commit('setIsClientEditor', true);
    router.options.clienteditor_routes.forEach(r => router.addRoute(r));
    //await router.replace('/clditor/home');
    //router.replace('/');
  }
  else {
    store.commit('setUserRole', "User");
    store.commit('setIsClientAdmin', false);
    store.commit('setIsAdmin', false);
    store.commit('setIsSuperAdmin', false);
    store.commit('setIsAccountant', false);
    store.commit('setIsTechnician', false);
    store.commit('setIsClientProducer', false);
    store.commit('setIsClientEditor', false);

    await router.replace('/');
  }
  //router.replace('/');
});


emitter.on("user-logout", () => {
  store.commit('setToken', "");
  store.commit('setIsSuperAdmin', false);
  store.commit('setIsAdmin', false);
  store.commit('setIsClientAdmin', false);
  store.commit('setIsAccountant', false);
  store.commit('setIsTechnician', false);
  store.commit('setIsClientProducer', false);
  store.commit('setIsClientEditor', false);
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  window.location.reload();
});


app.mount('#app')
